import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({ show, onConfirm, onCancel, message }) => {
  return (
    <Modal
      show={show}
      onHide={onCancel}
      message
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{message ? message : ''}</h4>
        <h5>Do you want to update with this advocate as well?</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={onConfirm}>
          Yes
        </Button>
        <Button variant="danger" id="btnDiscard" onClick={onCancel}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Modal, Card } from 'react-bootstrap';
import EnlargableTextbox from 'components/common/EnlargableTextbox';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { useSelector, useDispatch } from 'react-redux';
import { formChangedAction } from 'actions';
import { stateListAPI, stateMasterDetailAction } from 'actions/StateMaster/stateMasterAction.js';
import { toast } from 'react-toastify';

const StateMaster = () => {
	const dispatch = useDispatch();
	const [rowData, setRowData] = useState([]);
	const [perPage, setPerPage] = useState(15);
	const [modalShow, setModalShow] = useState(false);
	const [paramsData, setParamsData] = useState({});
	const [formHasError, setFormError] = useState(false);
	var responseCountryCode = localStorage.getItem("EncryptedResponseCountryCode")

	const columnsArray = [
		'S.No',
		'State Code',
		'State Name',
		'Delete'
	];
	
	const emptyRow = {
		id: rowData.length + 1,
		encryptedCountryCode: localStorage.getItem("EncryptedResponseCountryCode"),
		countryCode: '',
		stateCode: '',
		stateName: '',
		addUser: localStorage.getItem("LoginUserName"),
	}

	const stateMasterReducer = useSelector((state) => state.rootReducer.stateMasterReducer)
	const stateMasterData = stateMasterReducer.stateMasterDetail;

	const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
	var formChangedData = formChangedReducer.formChanged;

	const stateMasterErrorReducer = useSelector((state) => state.rootReducer.stateMasterErrorReducer)
	const stateDetailsError = stateMasterErrorReducer.stateDetailsError;
	
	useEffect(() => {
		if (stateMasterReducer.stateMasterDetail.length > 0) {
			setRowData(stateMasterData);
		}
	}, [stateMasterData, stateMasterReducer])

	useEffect(() => {
		if (responseCountryCode) {
			getStates(responseCountryCode)
		}
	}, [responseCountryCode]);

	const getStates = async (selectCountryCode) => {
		const data = {
			countryCode: selectCountryCode ? selectCountryCode : "",
			isList: true
		}
		await dispatch(stateListAPI(
			data,
			(res) => {

				if (res && res.length > 0) {
					dispatch(stateMasterDetailAction(res))

				} else {
					dispatch(stateMasterDetailAction([]))
				}
			},
			(err) => {
				console.log("something went wrong")
				dispatch(stateMasterDetailAction([]))
			}
		));
	};

  const validateStateDetailsForm = () => {
    let isValid = true;
    if (stateMasterData && stateMasterData.length > 0) {
      const seenCombination = {};
      stateMasterData.forEach((row, index) => {
        if (!row.stateName) {
          isValid = false;
          setFormError(true);
        } else {
          const combinationString = `${row.stateName}`.toLowerCase();
          if (seenCombination[combinationString]) {
            toast.error(
              `The state name "${row.stateName}" is already in use!`,
              {
                theme: 'colored',
                autoClose: 10000
              }
            );
            isValid = false;
            setFormError(true);
          } else {
            seenCombination[combinationString] = true;
          }
        }
      });
    }

		if (isValid) {
			setFormError(false);
		}

		return isValid;
	};

	const handleAddRow =  () => {
		let formValid = validateStateDetailsForm();
		if (formValid) {
			stateMasterData.unshift(emptyRow);
			dispatch(stateMasterDetailAction(stateMasterData));
		}
	}

	const handleFieldChange = (e, index) => {
		const { name, value } = e.target;
		var stateData = [...rowData];
		stateData[index][name] = value;
		stateData = Object.keys(rowData).map(key => {
			return rowData[key];
		});
		dispatch(stateMasterDetailAction(stateData));
		if (stateData[index].encryptedStateCode) {
			dispatch(
				formChangedAction({
					...formChangedData,
					stateDetailUpdate: true
				})
			);
		} else {
			dispatch(
				formChangedAction({
					...formChangedData,
					stateDetailAdd: true
				})
			);
		}
	}

	const ModalPreview = (encryptedStateCode, rowId) => {
		setModalShow(true);
		setParamsData({ encryptedStateCode, rowId });
	}

	const deleteStateDetail = () => {
		if (!paramsData) return false;
		var objectIndex = stateMasterReducer.stateMasterDetail.findIndex(x => x.encryptedStateCode == paramsData.encryptedStateCode);
		stateMasterReducer.stateMasterDetail.splice(objectIndex, 1);

		var stateCode = localStorage.getItem("DeleteStateCodes");

		if (paramsData.encryptedStateCode) {
			var deleteStateCodes = stateCode ? stateCode + "," + paramsData.encryptedStateCode : paramsData.encryptedStateCode;
			localStorage.setItem("DeleteStateCodes", deleteStateCodes);
		}
		toast.success("State details deleted successfully", {
			theme: 'colored'
		});
		dispatch(stateMasterDetailAction(stateMasterData));
		dispatch(formChangedAction({
			...formChangedData,
			stateMasterDelete: true
		}))

		setModalShow(false);
	};

	
	return (
		<>
			{modalShow && paramsData &&
				<Modal
					show={modalShow}
					onHide={() => setModalShow(false)}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">Confirmation</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h4>Are you sure, you want to delete this State detail?</h4>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="success" onClick={() => setModalShow(false)}>Cancel</Button>
						<Button variant="danger" onClick={() => deleteStateDetail()}>Delete</Button>
					</Modal.Footer>
				</Modal>
			}
			<Card className="h-100 mb-2">
				<FalconCardHeader
					title="State Details"
					titleTag="h6"
					className="py-2"
					light
					endEl={
						<Flex>
							<div >
								<Button
									variant="primary"
									size="sm"
									className="btn-reveal"
									type="button"
									onClick={handleAddRow}
								>
									Add State
								</Button>
							</div>

						</Flex>
					}
				/>
				{
					stateMasterData && stateMasterData.length > 0 &&
					<Card.Body className="position-relative pb-0 p3px mr-table-card">
						<Form
							noValidate
							validated={formHasError || (stateDetailsError.stateNameErr && stateDetailsError.stateNameErr.nameEmpty)}
							className="details-form"
							id="AddStateDetailsForm"
						>
			  <div className="table-responsive">
							<Table striped bordered responsive id="TableList" className="no-pb text-nowrap tab-page-table">
							<thead className='custom-bg-200'>
                  <tr>
                    {columnsArray.map((column, index) => (
                      <th className="text-left" key={index}>
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
								<tbody id="tbody" className="details-form">
									{rowData.map((stateDataDetail, index) => (
										<tr key={index}>
											<td>
												{index + 1}
											</td>
											<td key={index}>
												<EnlargableTextbox
													name="stateCode"
													placeholder="State Code"
													value={stateDataDetail.stateCode}
													maxLength={5}
													disabled
													className="form-control"
													required
												/>
											</td>
											<td key={index}>
												<Form.Control
													name="stateName"
													placeholder="State Name"
													onChange={(e) => handleFieldChange(e, index)}
													value={stateDataDetail.stateName}
													maxLength={50}
													className="form-control"
													required
												/>
											</td>
											<td >
												<FontAwesomeIcon icon={'trash'} className="fa-2x" onClick={() => { ModalPreview(stateDataDetail.encryptedStateCode, stateDataDetail.id) }} />
											</td>
										</tr>
									))}
								</tbody>
							</Table>
							</div>
						</Form>
					</Card.Body>
				}
			</Card>
		</>
	)
}
export default StateMaster
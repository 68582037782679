import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const  GET_CASE_SCHEDULE_DATE_LIST_REQUEST= 'GET_CASE_SCHEDULE_DATE_LIST_REQUEST';
export const GET_CASE_SCHEDULE_DATE_LIST_SUCCESS = 'GET_CASE_SCHEDULE_DATE_LIST_SUCCESS';
export const GET_CASE_SCHEDULE_DATE_LIST_FAILURE = 'GET_CASE_SCHEDULE_DATE_LIST_FAILURE';
export const  GET_CASE_DATE_PDF_LIST_REQUEST= 'GET_CASE_DATE_PDF_LIST_REQUEST'; 
export const GET_CASE_DATE_PDF_LIST_SUCCESS = 'GET_CASE_DATE_PDF_LIST_SUCCESS';
export const GET_CASE_DATE_PDF_LIST_FAILURE = 'GET_CASE_DATE_PDF_LIST_FAILURE';
import axios from 'axios';

  export const getCaseScheduleDateListRequest = () => ({
    type: GET_CASE_SCHEDULE_DATE_LIST_REQUEST
  });
  export const getCaseScheduleDateListSuccess = payload => ({
    type: GET_CASE_SCHEDULE_DATE_LIST_REQUEST,
    payload
  });
  export const getCaseScheduleDateListFailure = payload => ({
    type: GET_CASE_SCHEDULE_DATE_LIST_FAILURE,
    payload
  });

  export const getCaseDatePDFListRequest = () => ({
    type: GET_CASE_DATE_PDF_LIST_REQUEST
  });
  export const getCaseDatePDFListSuccess = payload => ({
    type: GET_CASE_SCHEDULE_DATE_LIST_REQUEST,
    payload
  });
  export const getCaseDatePDFListFailure = payload => ({
    type: GET_CASE_SCHEDULE_DATE_LIST_FAILURE,
    payload
  });

  export const scheduleCaseDateListAction = payload => {
    return {
      type: 'SCHEDULECASEDATELIST',
      payload
    };
  };

  export const scheduleCaseDateAction = payload => {
    return {
      type: 'SCHEDULECASEDATE',
      payload
    };
  };

export const getScheduleCaseDateListAPI = (payload, successData, errorMSg, headers = {}) => {
  return dispatch => {
    dispatch(getCaseScheduleDateListRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/get-schedule-case-date-list'}`,
      payload,
      "",
      headers
    )
      .then(res => {
        if (res?.data.status === 200) {
          successData(res.data);
          dispatch(getCaseScheduleDateListSuccess(res));
          
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(getCaseScheduleDateListFailure('Something went wrong'));
        throw err;
      });
  };
};

export const downloadCaseDatePdfAPI = (payload, headers, successCallback) => {
  return dispatch => {
    dispatch(getCaseScheduleDateListRequest());
    axios.post(
      `${REACT_APP_API_URL}/get-case-date-pdf-list`,
      payload,
      {
        responseType: 'blob', 
        headers: headers, 
      }
    )
      .then(response => {
        if (response.headers['content-type'] === 'application/pdf') {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const downloadUrl = URL.createObjectURL(blob);
          window.open(downloadUrl, '_blank');
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'CaseSchedule.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch(getCaseDatePDFListSuccess());
        } else {
          dispatch(getCaseDatePDFListFailure(response.data.message));
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(
          getCaseDatePDFListFailure(
            'Something went wrong, please try again.'
          )
        );
      });
  };
};
import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;

export const GET_LITIGATION_TYPES_MASTER_LIST_REQUEST = 'GET_LITIGATION_TYPES_MASTER_LIST_REQUEST';
export const GET_LITIGATION_TYPES_MASTER_LIST_SUCCESS = 'GET_LITIGATION_TYPES_MASTER_LIST_SUCCESS';
export const GET_LITIGATION_TYPES_MASTER_LIST_FAILURE = 'GET_LITIGATION_TYPES_MASTER_LIST_FAILURE';
export const ADD_LITIGATION_TYPES_MASTER_REQUEST = 'ADD_LITIGATION_TYPES_MASTER_REQUEST';
export const ADD_LITIGATION_TYPES_MASTER_SUCCESS = 'ADD_LITIGATION_TYPES_MASTER_SUCCESS';
export const ADD_LITIGATION_TYPES_MASTER_FAILURE = 'ADD_LITIGATION_TYPES_MASTER_FAILURE';
export const UPDATE_LITIGATION_TYPES_MASTER_REQUEST = 'UPDATE_LITIGATION_TYPES_MASTER_REQUEST';
export const UPDATE_LITIGATION_TYPES_MASTER_SUCCESS = 'UPDATE_LITIGATION_TYPES_MASTER_SUCCESS';
export const UPDATE_LITIGATION_TYPES_MASTER_FAILURE = 'UPDATE_LITIGATION_TYPES_MASTER_FAILURE';

  export const getLitigationTypeMasterListRequest = () => ({
    type: GET_LITIGATION_TYPES_MASTER_LIST_REQUEST
  });
  export const getLitigationTypeMasterListSuccess = payload => ({
    type: GET_LITIGATION_TYPES_MASTER_LIST_SUCCESS,
    payload
  });
  export const getLitigationTypeMasterListFailure = payload => ({
    type: GET_LITIGATION_TYPES_MASTER_LIST_FAILURE,
    payload
  });

  export const addLitigationTypeMasterRequest = () => ({
    type: ADD_LITIGATION_TYPES_MASTER_REQUEST
  });
  export const addLitigationTypeMasterSuccess = payload => ({
    type: ADD_LITIGATION_TYPES_MASTER_SUCCESS,
    payload
  });
  export const addLitigationTypeMasterFailure = payload => ({
    type: ADD_LITIGATION_TYPES_MASTER_FAILURE,
    payload
  });

  export const updateLitigationTypeMasterRequest = () => ({
    type: UPDATE_LITIGATION_TYPES_MASTER_REQUEST
  });
  export const updateLitigationTypeMasterSuccess = payload => ({
    type: UPDATE_LITIGATION_TYPES_MASTER_SUCCESS,
    payload
  });
  export const updateLitigationTypeMasterFailure = payload => ({
    type: UPDATE_LITIGATION_TYPES_MASTER_FAILURE,
    payload
  });

  export const litigationDetailAction = payload => {
    return {
      type: 'LITIGATIONDETAIL',
      payload
    };
  };
  
  export const litigationDetailsErrorAction = payload => {
    return {
      type: 'LITIGATIONERROR',
      payload
    };
  };

  export const getLitigationTypeMasterListAPI = (payload, successData, errorMSg,headers={}) => {
    return (dispatch) => {
      dispatch(getLitigationTypeMasterListRequest());
      return request("POST", `${REACT_APP_API_URL + "/litigation-list"}`, payload, "", headers)
        .then((res) => {
          if (res?.data?.status === 200) {
            successData(res);
            dispatch(getLitigationTypeMasterListSuccess(res.data.data.litigationList));
          } else {
            errorMSg(res);
            dispatch(getLitigationTypeMasterListFailure(res));
          }
        })
        .catch((error) => {
          errorMSg(error?.response);
          dispatch(getLitigationTypeMasterListFailure('Something went wrong'));
          throw error;
        });
    }
  }

  export const addLitigationTypeMasterAPI = (payload, successData, errorMsg,header={}) => {
    return (dispatch) => {
        dispatch(addLitigationTypeMasterRequest());
        return request("POST", `${REACT_APP_API_URL + "/add-litigation-type-master"}`, payload,"",header)
            .then((response) => {
                if (response?.data?.status === 200) {
                    successData(response);
                    dispatch(addLitigationTypeMasterSuccess(response));
                } else {
                    errorMsg(response);
                }
  
            }).catch((error) => {
                errorMsg(error?.response);
                dispatch(addLitigationTypeMasterFailure ("Something Went Wrong"));
                throw error;
            });
    };
  };

  export const updateLitigationTypeMasterAPI = (payload, successData, errorMsg,header={}) => {
    return (dispatch) => {
        dispatch(updateLitigationTypeMasterRequest());
        return request("POST", `${REACT_APP_API_URL + "/update-country-master"}`, payload,"",header)
            .then((response) => {
                if (response?.data?.status === 200) {
                    successData(response);
                    dispatch(updateLitigationTypeMasterSuccess(response));
                } else {
                    errorMsg(response);
                }
  
            }).catch((error) => {
                errorMsg(error?.response);
                dispatch(updateLitigationTypeMasterFailure("Something Went Wrong"));
                throw error;
            });
    };
  };
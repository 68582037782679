import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { formChangedAction } from 'actions';
import {
  addCaseTypeMasterAPI,
  caseTypeMasterDataDetailAction,
  caseTypeMasterDetailErrorAction,
  caseTypeMasterListAPI,
  updateCaseTypeMasterAPI
} from 'actions/CaseTypeMaster/caseTypeMasterAction';
import TabPage1 from 'components/common/TabPage1';

const tabArray = ['Case Type List', 'Add Case Type'];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'caseCode', Header: 'Case Code' },
  { accessor: 'countryName', Header: 'Country Name' },
  { accessor: 'stateName', Header: 'State Name' },
  { accessor: 'courtName', Header: 'Court Name' },
  { accessor: 'caseType', Header: 'Case Type' },
  { accessor: 'status', Header: 'Status' }
];

export const CaseTypeMaster = () => {
  const dispatch = useDispatch();
  // const [perPage, setPerPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [caseTypeMasterList, setCaseTypeMasterList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [searchText, setSearchText] = useState('');

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getCaseTypeMasterList =  (page, size = pageSize, searchText) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: searchText ? searchText : ""
    };
    setIsLoading(true);
     dispatch(
      caseTypeMasterListAPI(
        data,
        response => {
          setIsLoading(false);
          setCaseTypeMasterList(response.data.data.caseTypeMasterList);
          setTotalCount(response.data.data.totalCount);
          console.log('data count', response.data.data.totalCount)
        },
        err => {
          setIsLoading(false);
          setCaseTypeMasterList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  useEffect(() => {
    localStorage.removeItem('EncryptedResponseCaseCode');
    getCaseTypeMasterList(1, pageSize);
    $('[data-rr-ui-event-key*="Add Case Type"]').attr('disabled', true);
  }, []);

  const caseTypeMasterReducer = useSelector(
    state => state.rootReducer.caseTypeMasterReducer
  );
  var caseTypeMasterData = caseTypeMasterReducer.caseTypeMasterDetail;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );

  const [formHasError, setFormError] = useState(false);
  const [activeTabName, setActiveTabName] = useState();

  const clearCaseTypeMasterReducer = () => {
    dispatch(caseTypeMasterDataDetailAction(undefined));
    dispatch(caseTypeMasterDetailErrorAction(undefined));
    dispatch(formChangedAction(undefined));
    localStorage.removeItem('EncryptedResponseCaseCode');
  };

  $('[data-rr-ui-event-key*="Case Type List"]')
    .off('click')
    .on('click', function () {
      let isDiscard = $('#btnDiscard').attr('isDiscard');
      if (isDiscard != 'true' && isFormChanged) {
        setModalShow(true);
        setTimeout(function () {
          $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
        }, 50);
      } else {
        $('#btnNew').show();
        $('#btnSave').hide();
        $('#btnCancel').hide();
        $('[data-rr-ui-event-key*="Add Case Type"]').attr('disabled', true);
        $('[data-rr-ui-event-key*="Case Type List"]').attr('disabled', false);
        $('#addCaseTypeMasterDetailsForm').get(0).reset();
        localStorage.removeItem('EncryptedResponseCaseCode');
        $('#btnDiscard').attr('isDiscard', false);
        clearCaseTypeMasterReducer();
      }
    });

  $('[data-rr-ui-event-key*="Add Case Type"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('Add Case Type');
      $('#btnNew').hide();
      $('#btnSave').show();
      $('#btnCancel').show();
    });

  const newDetails = () => {
    $('[data-rr-ui-event-key*="Add Case Type"]').attr('disabled', false);
    $('[data-rr-ui-event-key="Add Case Type"]').trigger('click');
    $('#btnSave').attr('disabled', false);
    clearCaseTypeMasterReducer();
  };

  const cancelClick = () => {
    $('#btnExit').attr('isExit', 'false');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      $('[data-rr-ui-event-key*="Case Type List"]').trigger('click');
    }
  };

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      window.location.href = '/dashboard';
    }
  };

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true') {
      window.location.href = '/dashboard';
    } else {
      $('[data-rr-ui-event-key*="List"]').trigger('click');
      setModalShow(false);
    }
  };

  const caseTypeMasterValidation = () => {
    const caseTypeErr = {};
    const countryNameErr = {};
    const stateNameErr = {};
    const courtNameErr = {};
    let isValid = true;

    if (!caseTypeMasterData.caseType) {
      caseTypeErr.nameEmpty = 'Enter case type';
      isValid = false;
      setFormError(true);
    }
    if (!caseTypeMasterData.countryCode) {
      countryNameErr.empty = 'Select country';
      isValid = false;
      setFormError(true);
    }
    if (!caseTypeMasterData.stateCode) {
      stateNameErr.empty = 'Select state';
      isValid = false;
      setFormError(true);
    }
    if (!caseTypeMasterData.courtCode) {
      courtNameErr.empty = 'Select court';
      isValid = false;
      setFormError(true);
    }
    if (!isValid) {
      var errorObject = {
        caseTypeErr,
        countryNameErr,
        stateNameErr,
        courtNameErr,
      };
      dispatch(caseTypeMasterDetailErrorAction(errorObject));
    }
    return isValid;
  };

  const addCaseTypeMasterDetails = () => {
    if (caseTypeMasterValidation()) {
      const requestData = {
        caseType: caseTypeMasterData.caseType,
        stateCode: caseTypeMasterData.stateCode,
        courtCode: caseTypeMasterData.courtCode,
        status: caseTypeMasterData.status,
        addUser: localStorage.getItem('LoginUserName')
      };
      const keys = [
        'caseType',
        'addUser'
      ];
      for (const key of Object.keys(requestData).filter(key =>
        keys.includes(key)
      )) {
        requestData[key] = requestData[key]
          ? requestData[key].toUpperCase().trim()
          : '';
      }

      setIsLoading(true);
      dispatch(
        addCaseTypeMasterAPI(
          requestData,
          response => {
            setIsLoading(false);
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });
            dispatch(
              caseTypeMasterDataDetailAction({
                ...caseTypeMasterData,
                encryptedCaseCode: response.data.data.encryptedCaseCode,
                caseCode: response.data.data.caseCode
              })
            );
            dispatch(caseTypeMasterDetailErrorAction(undefined));
            updateCallback(true);
            setModalShow(false);
            localStorage.setItem(
              'EncryptedResponseCaseCode',
              response.data.data.encryptedCaseCode
            );
          },
          err => {
            setIsLoading(false);
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          },
          headers
        )
      );
    }
  };

  const updateCallback = (isAddCaseTypeMaster = false) => {
    setModalShow(false);
    dispatch(formChangedAction(undefined));
    if (!isAddCaseTypeMaster) {
      toast.success('Case type detail updated successfully!', {
        theme: 'colored'
      });
    }

    $('#btnSave').attr('disabled', true);
    getCaseTypeMasterList(1, pageSize);

    $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
  };

  const updateCaseTypeMasterDetails = async () => {
    if (caseTypeMasterValidation()) {
      const updateRequestData = {
        encryptedCaseCode: localStorage.getItem(
          'EncryptedResponseCaseCode'
        ),
        caseType: caseTypeMasterData.caseType,
        stateCode: caseTypeMasterData.stateCode,
        courtCode: caseTypeMasterData.courtCode,
        status: caseTypeMasterData.status,
        modifyUser: localStorage.getItem('LoginUserName')
      };

      const keys = [
        'caseType',
        'modifyUser'
      ];
      for (const key of Object.keys(updateRequestData).filter(key =>
        keys.includes(key)

      )) {
        updateRequestData[key] = updateRequestData[key]
          ? updateRequestData[key].toUpperCase().trim()
          : '';
      }

      if (formChangedData.caseTypeMasterUpdate) {
        setIsLoading(true);
        dispatch(
          updateCaseTypeMasterAPI(
            updateRequestData,
            response => {
              updateCallback(false);
              setIsLoading(false);
              setModalShow(false);
            },
            err => {
              setIsLoading(false);
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
            },
            headers
          )
        );
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={
                !caseTypeMasterData.encryptedCaseCode
                  ? addCaseTypeMasterDetails
                  : updateCaseTypeMasterDetails
              }
            >
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}
            >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <TabPage1
        listData={caseTypeMasterList}
        listColumnArray={listColumnArray}
        tabArray={tabArray}
        module="CaseTypeMaster"
        saveDetails={
          caseTypeMasterData.encryptedCaseCode
            ? updateCaseTypeMasterDetails
            : addCaseTypeMasterDetails
        }
        newDetails={newDetails}
        cancelClick={cancelClick}
        exitModule={exitModule}
        pageSize={pageSize}
        setPageSize={(value)=> setPageSize(value)}
        searchText={searchText}
        setSearchTextValue={(value)=> setSearchText(value)}
        fetchList = {getCaseTypeMasterList}
        listCount={totalCount}
      />
    </>
  );
};

export default CaseTypeMaster;


const initialState = {
    litigationTypeDetailError: {
      litigationDetailNameErr: {},
    }
  };
  
  const litigationTypeDetailErrorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LITIGATIONTYPEDETAILERROR':
        if (!action.payload) {
          return initialState;
        } else {
          return {
            ...state,
            litigationTypeMasterError: action.payload
          };
        }
      default:
        return state;
    }
  };
  
  export default litigationTypeDetailErrorReducer;
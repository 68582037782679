import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formChangedAction } from 'actions';
import FalconComponentCard from 'components/common/FalconComponentCard.js';
import { litigationDetailAction } from 'actions/LitigationTypesMaster/litigationTypesMasterAction';

const LitigationTypeMasterDetail = () => {
	const dispatch = useDispatch();

	const resetLitigationTypeMasterData = () => {
		dispatch(
			litigationDetailAction({
				"litigationName": '',
				"litigationTypeCode": ''
			})
		);
	}

	const litigationTypesMasterReducer = useSelector((state) => state.rootReducer.litigationTypesMasterReducer)
	var litigationTypeMasterData = litigationTypesMasterReducer.litigationTypeMaster;

	const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
	var formChangedData = formChangedReducer.formChanged;

	const litigationTypeMasterErrorReducer = useSelector((state) => state.rootReducer.litigationTypeMasterErrorReducer)
	const litigationTypeMasterErr = litigationTypeMasterErrorReducer.litigationTypeMasterError;

	// if (
	// 	!litigationTypesMasterReducer.litigationTypeMasterDetail ||
	// 	Object.keys(litigationTypesMasterReducer.litigationTypeMasterDetail).length <= 0
	// ) {
	// 	resetLitigationTypeMasterData();
	// }

	const handleLitigationTypeFieldChange = e => {
		if (e.target.name == 'litigationName') {
			dispatch(
				litigationDetailAction({
					...litigationTypeMasterData,
					litigationName: e.target.value,
				})
			);
		}

		if (litigationTypeMasterData.encryptedLitigationCode) {
			dispatch(
				formChangedAction({
					...formChangedData,
					litigationTypeMasterUpdate: true
				})
			);
		} else {
			dispatch(
				formChangedAction({
					...formChangedData,
					litigationTypeMasterAdd: true
				})
			);
		}
	};

	return (
		<>
			<FalconComponentCard className="no-pb mb-1">
				<FalconComponentCard.Body language="jsx">
					<Form className="details-form"
						id="addLitigationTypeMasterDetailsForm">
						<Row>
							<Col md="4">
								<Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
									<Form.Label column sm="4">
										Litigation Code
									</Form.Label>
									<Col sm="8">
										<Form.Control id="txtLitigationTypeCode" name="litigationTypeCode" maxLength={5} placeholder="Litigation Code"
											value={litigationTypeMasterData.litigationCode}
											disabled />
									</Col>
								</Form.Group>
							</Col>
							<Col md="4">
								<Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
									<Form.Label column sm="4">
										Litigation Name<span className="text-danger">*</span>
									</Form.Label>
									<Col sm="8">
										<Form.Control id="txtLitigationName" maxLength={50} name="litigationName" placeholder="Litigation Name"
											value={litigationTypeMasterData.litigationDetailName}
											onChange={(e) => handleLitigationTypeFieldChange(e)}
										/>
										{Object.keys(litigationTypeMasterErr.litigationNameErr).map(key => {
											return (
												<span className="error-message">
													{litigationTypeMasterErr.litigationNameErr[key]}
												</span>
											);
										})}
									</Col>

								</Form.Group>
							</Col>
						</Row>
					</Form>
				</FalconComponentCard.Body>
			</FalconComponentCard>
		</>
	)
}

export default LitigationTypeMasterDetail
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import FalconComponentCard from '../FalconComponentCard';
import TabPage1 from '../TabPage1';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clientListAPI } from 'actions/ClientRegistration/ClientRegistrationAction';
import AdvanceTableComponent2 from '../advance-table-2/AdvanceTableComponent2';
// import ClientCaseDetailModal from '../Modal/ClientCaseDetailModal';

const ClientListCard = () => {
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [searchText, setSearchText] = useState('');

  // const [showModal, setShowModal] = useState(false);
  // const [selectedCase, setSelectedCase] = useState(null);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getClientList = (page, size = pageSize, searchText) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: searchText ? searchText : '',
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode')
    };
    setIsLoading(true);
    dispatch(
      clientListAPI(
        data,
        response => {
          setIsLoading(false);
          setClientList(response.data.data.clientList);
          setTotalCount(response.data.data.totalCount);
        },
        err => {
          setIsLoading(false);
          setClientList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  useEffect(() => {
    getClientList(1, pageSize);
  }, []);

  // const handleCaseClick = (rowData) => {
  //   // setSelectedCase(rowData.clientCode);
  //   setShowModal(true);
  // };

  const columns = [
    {
      accessorKey: 'clientName',
      header: 'Client Name',
      meta: { headerProps: { className: 'text-dark' } }
    },
    {
      accessorKey: 'mobileNo',
      header: 'Mobile No',
      meta: { headerProps: { className: 'text-dark' } }
    },
    {
      accessorKey: 'emailId',
      header: 'Email',
      meta: { headerProps: { className: 'text-dark' } }
    },
    {
      accessorKey: 'totalNoOfCase',
      header: 'No Of Case',
      meta: {
        headerProps: { className: 'text-dark' },
        // cellProps: {
        //   onClick: (row) => handleCaseClick(row)
        // }
      }
    }
  ];

  return (
    <>
      <div>
      <Card className="card-dashbord-bg mb-2 mt-1 client-card-header  border-0">
        <Row className="align-items-center m-0 p-0">
          <Col md={11} className="p-0">
            <Card.Header className=" fw-bolder text-center  text-white client-list-header">
            My Client List
            </Card.Header>
          </Col>
        </Row>
      </Card>
        <FalconComponentCard className="client-card ">
          {isLoading ? (
            <Spinner
              className="position-absolute start-50 loader-color "
              animation="border"
            />
          ) : null}
          <FalconComponentCard.Body className="bg-white remove-border-client table-advance">
            <AdvanceTableComponent2
              data={clientList}
              columns={columns}
              pageSize={pageSize}
              setPageSize={0}
              searchText={searchText}
              setSearchTextValue={value => setSearchText(value)}
              fetchList={getClientList}
              dataCount={totalCount}
            />
          </FalconComponentCard.Body>
        </FalconComponentCard>
        {/* <ClientCaseDetailModal
          show={showModal}
          hide={() => setShowModal(false)}
        /> */}
      </div>
    </>
  );
};

export default ClientListCard;
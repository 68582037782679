import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { formChangedAction } from 'actions';
import { addAdvocateClientCaseAPI, advocateClientCaseDataAction, advocateClientCaseErrorAction, advocateClientCaseListAPI, deleteAdvocateClientCaseAPI, resetCaseDeletedAction, updateAdvocateClientCaseAPI } from 'actions/AdvocateClientCase/AdvocateClientCaseAction';
import { toast } from 'react-toastify';
import { clientListAPI } from 'actions/ClientRegistration/ClientRegistrationAction';
import Moment from "moment";
import TabPage1 from 'components/common/TabPage1';

const tabArray = ['Case List', 'Case Details'];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'clientCode', Header: 'Client Code' },
  { accessor: 'clientName', Header: 'Client Name' },
  { accessor: 'stateName', Header: 'State Name' },
  { accessor: 'totalNoOfCase', Header: 'Total No. Of Case' },
];

const listColumnArray1 = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'clientCaseNo', Header: 'Case No' },
  {
    accessor: 'caseRegisterDate',
    Header: 'Register Date',
    Cell: ({ value }) => {
      return value ? Moment(value).format('DD-MM-YYYY') : '';
    },
  },
  {
    accessor: 'nextHearingDate',
    Header: 'Next Hearing Date',
    Cell: ({ value }) => {
      return value ? Moment(value).format('DD-MM-YYYY') : '';
    },
  },
  { accessor: 'attendantAdvocateName', Header: 'Attendant  Advocate' },
  { accessor: 'caseStatus', Header: 'Case Status' },
  { accessor: 'deleteCase', Header: 'Delete' },
];

export const AdvocateClientCase = () => {
  const dispatch = useDispatch();
  const [advocateClientCaseList, setAdvocateClientCaseList] = useState([]);
  const [clientCaseList, setClientCaseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [formHasError, setFormError] = useState(false);
  const [activeTabName, setActiveTabName] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clientList, setClientList] = useState([]);
  // const clientCode = localStorage.getItem('ClientCode')
  const selectedCaseId = localStorage.getItem('EncryptedResponseCaseId')
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [searchText, setSearchText] = useState("");

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  let oldCaseStatus = localStorage.getItem("OldCaseStatus");

  const getClientList = async (page, size = pageSize) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: '',
      EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
      isDropDown: true
    };
    setIsLoading(true);
    await dispatch(
      clientListAPI(
        data,
        res => {
          setIsLoading(false)
          let clientData = [];

          if (res.data.data.clientList.length > 0)
            res.data.data.clientList.forEach(client => {
              clientData.push({
                key: client.clientName,
                value: client.clientCode,
                label: client.clientName
              });
            });
          setClientList(clientData);
          if (res.data.data.clientList && res.data.data.clientList.length === 1) {
            getAdvocateClientCaseList(1, pageSize, "", res.data.data.clientList[0].clientCode)
          }
        },
        err => {
          setClientList([]);
          setIsLoading(false)
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const getAdvocateClientCaseList = async (page, size = pageSize, searchText, selectedClientCode) => {
    const data = {
      pageNumber: page,
      PageSize: size,
      searchText: searchText ? searchText : "",
      encryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
      clientCode: selectedClientCode ? selectedClientCode : localStorage.getItem('ClientCode')
    };
    setIsLoading(true);
    await dispatch(
      advocateClientCaseListAPI(
        data,
        response => {
          setIsLoading(false)
          if(data.clientCode)
          {
            setClientCaseList(response.data.data.advocateCaseList);
            setTotalCount(response.data.data.totalCount);
          }
          else
          {
            localStorage.removeItem("ClientCode")
            setAdvocateClientCaseList(response.data.data.advocateCaseList);
            setTotalCount(response.data.data.totalCount);
          }
        },
        err => {
          setIsLoading(false)
          setAdvocateClientCaseList([]);
          setClientCaseList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  useEffect(() => {
    localStorage.removeItem("ClientCode")
    getAdvocateClientCaseList(1, pageSize, searchText);
    // if (!localStorage.getItem("ClientCode")) {
    //   getAdvocateClientCaseList(1, pageSize);
    // }
    getClientList(1)
    if (oldCaseStatus === "DISPOSED") {
      $('#btnSave').attr('disabled', true);
      $("#btnSave").hide();
    }
  }, []);

  const advocateClientCaseReducer = useSelector(
    state => state.rootReducer.advocateClientCaseReducer
  );
  var advocateClientCaseData = advocateClientCaseReducer.advocateClientCaseData

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );
  const caseDeleted = useSelector(state => state.rootReducer.advocateClientCaseReducer.caseDeleted);

  useEffect(() => {
    if (caseDeleted) {
      getAdvocateClientCaseList(1, pageSize, searchText)
      dispatch(resetCaseDeletedAction(false));
    }
  }, [caseDeleted, dispatch]);

  const clearAdvocateClientCaseReducers = () => {
    dispatch(advocateClientCaseDataAction(undefined))
    dispatch(advocateClientCaseErrorAction(undefined))
    dispatch(formChangedAction(undefined));
  };

  $('[data-rr-ui-event-key*="Case List"]')
    .off('click')
    .on('click', function () {
      let isDiscard = $('#btnDiscard').attr('isDiscard');
      if (isDiscard != 'true' && isFormChanged) {
        setModalShow(true);
        setTimeout(function () {
          $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
        }, 50);
      } else {
        $('#btnNew').show();
        $('#btnCancel').hide();
        $('#btnSave').hide();
        $('[data-rr-ui-event-key*="Case Details"]').attr('disabled', true);
        $('[data-rr-ui-event-key*="Case List"]').attr('disabled', false);
        $('#addAdvocateClientCaseDetailsForm').get(0).reset();
        localStorage.removeItem("EncryptedResponseCaseId");
        localStorage.removeItem("OldCaseStatus")
        $('#btnDiscard').attr('isDiscard', false);
        clearAdvocateClientCaseReducers();
      }
    });

  $('[data-rr-ui-event-key*="Case Details"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('Case Details');
      $('#btnNew').hide();
      $('#btnCancel').show();
      if (oldCaseStatus === "DISPOSED") {
        $('#btnSave').hide();
      } else {
        $('#btnSave').show();
      }
    });

  const newDetails = () => {
    $('[data-rr-ui-event-key*="Case Details"]').attr('disabled', false);
    $('[data-rr-ui-event-key="Case Details"]').trigger('click');
    $('#btnSave').attr('disabled', false);
    clearAdvocateClientCaseReducers();
  };

  const cancelClick = () => {
    $('#btnExit').attr('isExit', 'false');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      $('[data-rr-ui-event-key*="Case List"]').trigger('click');
    }
  };

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      window.location.href = '/dashboard';
      dispatch(advocateClientCaseDataAction(undefined))
      localStorage.removeItem("EncryptedResponseCaseId")
      localStorage.removeItem("OldCaseStatus");
    }
  };

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true')
      window.location.href = '/dashboard';
    else $('[data-rr-ui-event-key*="List"]').trigger('click');

    setModalShow(false);
  };

  const advocateClientCaseValidation = () => {
    const clientNameErr = {};
    const caseNoErr = {};
    const countryErr = {};
    const stateErr = {};
    const courtNameErr = {};
    const caseTypeErr = {};
    const registerDateErr = {};
    const nextHearingDateErr = {};
    const addvocateMobileNoErr = {};
    const caseTitleErr = {};


    let isValid = true;
    if (!localStorage.getItem('EncryptedResponseCaseId') && !advocateClientCaseData.clientName) {
      clientNameErr.empty = 'Select client';
      isValid = false;
      setFormError(true);
    }

    if (!advocateClientCaseData.countryCode) {
      countryErr.empty = 'Select country';
      isValid = false;
      setFormError(true);
    }
    if (!advocateClientCaseData.caseCode) {
      caseTypeErr.empty = 'Select case type';
      isValid = false;
      setFormError(true);
    }

    if (!advocateClientCaseData.stateCode) {
      stateErr.empty = 'Select state';
      isValid = false;
      setFormError(true);
    }

    if (!advocateClientCaseData.clientCaseNo) {
      caseNoErr.caseNoEmpty = 'Please enter case no';
      isValid = false;
      setFormError(true);
    }

    if (!advocateClientCaseData.courtCode) {
      courtNameErr.caseNoEmpty = 'Select court name';
      isValid = false;
      setFormError(true);
    }
    if (!advocateClientCaseData.caseRegisterDate) {
      registerDateErr.regDateEmpty = 'Select register date';
      isValid = false;
      setFormError(true);
    }
    if (!advocateClientCaseData.nextHearingDate) {
      nextHearingDateErr.nxtHearingDateEmpty = 'Select next hearing date';
      isValid = false;
      setFormError(true);
    }
    else if (advocateClientCaseData.nextHearingDate <= advocateClientCaseData.caseRegisterDate) {
      nextHearingDateErr.endDateInvalid = "Next hearing date cannot be same or less than register Date";
      isValid = false;
      setFormError(true);
    }

    if (advocateClientCaseData.oppAdvMob) {
      if (!/^(?!0)[0-9]{10}$/.test(advocateClientCaseData.oppAdvMob)) {
        addvocateMobileNoErr.phoneInvalid =
          'Invalid mobile number, number should be of 10 digits should not start with 0';
        isValid = false;
        setFormError(true);
      }
    }
    if (!advocateClientCaseData.caseTitle) {
      caseTitleErr.caseNoEmpty = 'Please enter case title';
      isValid = false;
      setFormError(true);
    }

    // if (isValid) {
    //   setIsSubmitting(true);
    // }
    if (!isValid) {
      var errorObject = {
        clientNameErr,
        caseNoErr,
        countryErr,
        stateErr,
        caseTypeErr,
        courtNameErr,
        registerDateErr,
        nextHearingDateErr,
        addvocateMobileNoErr,
        caseTitleErr
      };
      dispatch(advocateClientCaseErrorAction(errorObject));
    }

    return isValid;
  };

  const addAdvocateClientDetails = async () => {
    if (advocateClientCaseValidation()) {

      if (isSubmitting) return
      const data = {
        EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
        clientCode: advocateClientCaseData.clientCode ? advocateClientCaseData.clientCode : localStorage.getItem('ClientCode'),
        AttendantAdvocateName: advocateClientCaseData.attendantAdvocateName ? advocateClientCaseData.attendantAdvocateName : "",
        CaseStatus: advocateClientCaseData.caseStatus,
        ClientCaseNo: advocateClientCaseData.clientCaseNo,
        ClientCode: advocateClientCaseData.client_Code,
        CourtCode: advocateClientCaseData.courtCode ? advocateClientCaseData.courtCode : "",
        RoomNo: advocateClientCaseData.roomNo ? advocateClientCaseData.roomNo : "",
        Almirah: advocateClientCaseData.almirah ? advocateClientCaseData.almirah : "",
        StateCode: advocateClientCaseData.stateCode,
        FileDescription: advocateClientCaseData.fileDescription ? advocateClientCaseData.fileDescription : "",
        Rack: advocateClientCaseData.rack ? advocateClientCaseData.rack : "",
        JudgeName: advocateClientCaseData.judgeName ? advocateClientCaseData.judgeName : "",
        CaseTypeCode: advocateClientCaseData.caseCode,
        CourtNo: advocateClientCaseData.courtNo ? advocateClientCaseData.courtNo : "",
        CaseRegisterDate: advocateClientCaseData.caseRegisterDate,
        NextHearingDate: advocateClientCaseData.nextHearingDate,
        ConsultingFees: advocateClientCaseData.consultingFees ? advocateClientCaseData.consultingFees : "0.00",
        FileNo: advocateClientCaseData.fileNo ? advocateClientCaseData.fileNo : "",
        PoliceStation: advocateClientCaseData.policeStation ? advocateClientCaseData.policeStation : "",
        FIRDate: advocateClientCaseData.firDate ? advocateClientCaseData.firDate : null,
        CaseNature: advocateClientCaseData.caseNature ? advocateClientCaseData.caseNature : "",
        CaseTitle: advocateClientCaseData.caseTitle,
        Purpose: advocateClientCaseData.purpose ? advocateClientCaseData.purpose : "",
        OppAdvName: advocateClientCaseData.oppAdvName ? advocateClientCaseData.oppAdvName : "",
        OppAdvMob: advocateClientCaseData.oppAdvMob ? advocateClientCaseData.oppAdvMob : "",
        UnderSection: advocateClientCaseData.underSection ? advocateClientCaseData.underSection : "",
        FIRNo: advocateClientCaseData.firNo ? advocateClientCaseData.firNo : "",
        Party: advocateClientCaseData.party ? advocateClientCaseData.party : "",
        EmailId: advocateClientCaseData.emailId ? advocateClientCaseData.emailId : "",
        AddUser: localStorage.getItem('LoginUserName')
      }
      const keys = [
        'AttendantAdvocateName',
        'CaseStatus',
        'FileDescription',
        'JudgeName',
        'PoliceStation',
        'CaseNature',
        'CaseTitle',
        'OppAdvName',
        'EmailId',
        'FileNo',
        'Rack',
        'FIRNo',
        'UnderSection',
        'CourtNo',
        'Almirah',
        'AddUser'
      ];
      for (const key of Object.keys(data).filter(key => keys.includes(key))) {
        data[key] = data[key] ? data[key].toUpperCase() : '';
      }
      setIsLoading(true);
      await dispatch(
        addAdvocateClientCaseAPI(
          data,
          response => {
            setIsLoading(false);
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });
            updateCallback(true)
            setModalShow(false);
            localStorage.setItem(
              'EncryptedResponseCaseId',
              response.data.data.encryptedAdvocateClientCaseId
            );
            localStorage.setItem("OldCaseStatus", advocateClientCaseData.caseStatus);
          },
          err => {
            setIsLoading(false)
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          },
          headers
        )
      );
    }
  };

  const updateCallback = async (isAddAdvocateCaseClient = false) => {
    setModalShow(false);
    setIsSubmitting(false)
    dispatch(formChangedAction(undefined));
    dispatch(advocateClientCaseErrorAction(undefined));

    if (!isAddAdvocateCaseClient) {
      toast.success("Advocate client case details updated successfully!", {
        theme: 'colored'
      });
    }

    $('#btnSave').attr('disabled', true)
    if (!localStorage.getItem('ClientCode')) {
      getAdvocateClientCaseList(1, pageSize, searchText);
    } else {
      getAdvocateClientCaseList(1, pageSize, searchText, localStorage.getItem('ClientCode'))
    }

    $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
  }

  const updateAdvocateClientCaseDetails = async () => {
    if (advocateClientCaseValidation()) {
      if (isSubmitting) return
      const data = {
        EncryptedCaseId: advocateClientCaseData.encryptedCaseId ? advocateClientCaseData.encryptedCaseId : selectedCaseId,
        AttendantAdvocateName: advocateClientCaseData.attendantAdvocateName ? advocateClientCaseData.attendantAdvocateName : "",
        CaseStatus: advocateClientCaseData.caseStatus,
        ClientCaseNo: advocateClientCaseData.clientCaseNo,
        CourtCode: advocateClientCaseData.courtCode ? advocateClientCaseData.courtCode : "",
        RoomNo: advocateClientCaseData.roomNo ? advocateClientCaseData.roomNo : "",
        Almirah: advocateClientCaseData.almirah ? advocateClientCaseData.almirah : "",
        StateCode: advocateClientCaseData.stateCode,
        FileDescription: advocateClientCaseData.fileDescription ? advocateClientCaseData.fileDescription : "",
        Rack: advocateClientCaseData.rack ? advocateClientCaseData.rack : "",
        JudgeName: advocateClientCaseData.judgeName ? advocateClientCaseData.judgeName : "",
        CaseTypeCode: advocateClientCaseData.caseCode,
        CourtNo: advocateClientCaseData.courtNo ? advocateClientCaseData.courtNo : "",
        CaseRegisterDate: advocateClientCaseData.caseRegisterDate,
        NextHearingDate: advocateClientCaseData.nextHearingDate,
        ConsultingFees: advocateClientCaseData.consultingFees ? advocateClientCaseData.consultingFees : "0.00",
        FileNo: advocateClientCaseData.fileNo ? advocateClientCaseData.fileNo : "",
        PoliceStation: advocateClientCaseData.policeStation ? advocateClientCaseData.policeStation : "",
        FIRDate: advocateClientCaseData.firDate ? advocateClientCaseData.firDate : null,
        CaseNature: advocateClientCaseData.caseNature ? advocateClientCaseData.caseNature : "",
        CaseTitle: advocateClientCaseData.caseTitle,
        Purpose: advocateClientCaseData.purpose ? advocateClientCaseData.purpose : "",
        OppAdvName: advocateClientCaseData.oppAdvName ? advocateClientCaseData.oppAdvName : "",
        OppAdvMob: advocateClientCaseData.oppAdvMob ? advocateClientCaseData.oppAdvMob : "",
        UnderSection: advocateClientCaseData.underSection ? advocateClientCaseData.underSection : "",
        FIRNo: advocateClientCaseData.firNo ? advocateClientCaseData.firNo : "",
        Party: advocateClientCaseData.party ? advocateClientCaseData.party : "",
        ModifyUser: localStorage.getItem('LoginUserName')
      }
      const keys = [
        'AttendantAdvocateName',
        'CaseStatus',
        'FileDescription',
        'JudgeName',
        'PoliceStation',
        'CaseNature',
        'CaseTitle',
        'OppAdvName',
        'EmailId',
        'FileNo',
        'Rack',
        'CourtNo',
        'Almirah',
        'FIRNo',
        'RoomNo',
        'UnderSection',
        'ModifyUser'
      ];
      for (const key of Object.keys(data).filter(key => keys.includes(key))) {
        data[key] = data[key] ? data[key].toUpperCase() : '';
      }
      setIsLoading(true);
      if (formChangedData.AdvocateClientCaseUpdate) {
        await dispatch(
          updateAdvocateClientCaseAPI(
            data,
            response => {
              setIsLoading(false);
              toast.success(` ${response.data.message}`, {
                theme: 'colored',
                autoClose: 10000
              });
              updateCallback(true)
              setModalShow(false);
              localStorage.setItem("OldCaseStatus", advocateClientCaseData.caseStatus);
            },
            err => {
              setIsLoading(false)
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
            },
            headers
          )
        );
      }
    }
  };

  const handleFieldChange = e => {
    if (e.target.value) {
      localStorage.setItem("ClientCode", e.target.value);
      getAdvocateClientCaseList(1, pageSize, searchText, e.target.value);
    } 
    else {
      localStorage.removeItem("ClientCode")
      getAdvocateClientCaseList(1, pageSize, searchText)
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}
      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={
                advocateClientCaseData.encryptedCaseId || selectedCaseId ? updateAdvocateClientCaseDetails :
                  addAdvocateClientDetails
              }
            >
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}
            >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <TabPage1
        listData={localStorage.getItem("ClientCode") ? clientCaseList : advocateClientCaseList}
        listColumnArray={localStorage.getItem("ClientCode") ? listColumnArray1 : listColumnArray}
        tabArray={tabArray}
        module="CaseRegistration"
        saveDetails={
          advocateClientCaseData.encryptedCaseId || selectedCaseId ? updateAdvocateClientCaseDetails :
            addAdvocateClientDetails
        }
        newDetails={newDetails}
        cancelClick={cancelClick}
        exitModule={exitModule}
        tableFilterOptions={clientList}
        tableFilterName={'Client'}
        supportingMethod1={handleFieldChange}
        pageSize={pageSize}
        setPageSize={(value)=> setPageSize(value)}
        searchText={searchText}
        setSearchTextValue={(value)=> setSearchText(value)}
        fetchList = {getAdvocateClientCaseList}
        listCount={totalCount}
      />
    </>
  );
};

export default AdvocateClientCase;
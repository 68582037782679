import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;

export const GET_LITIGATION_TYPES_DETAIL_LIST_REQUEST = 'GET_LITIGATION_TYPES_DETAIL_LIST_REQUEST';
export const GET_LITIGATION_TYPES_DETAIL_LIST_SUCCESS = 'GET_LITIGATION_TYPES_DETAIL_LIST_SUCCESS';
export const GET_LITIGATION_TYPES_DETAIL_LIST_FAILURE = 'GET_LITIGATION_TYPES_DETAIL_LIST_FAILURE';

  export const getLitigationTypeDetailListRequest = () => ({
    type: GET_LITIGATION_TYPES_DETAIL_LIST_REQUEST
  });
  export const getLitigationTypeDetailListSuccess = payload => ({
    type: GET_LITIGATION_TYPES_DETAIL_LIST_SUCCESS,
    payload
  });
  export const getLitigationTypeDetailListFailure = payload => ({
    type: GET_LITIGATION_TYPES_DETAIL_LIST_FAILURE,
    payload
  });

  export const litigationTypeDetailAction = payload => {
    return {
      type: 'LITIGATIONTYPEDETAIL',
      payload
    };
  };
  
  export const litigationTypeDetailsErrorAction = payload => {
    return {
      type: 'LITIGATIONTYPEDETALERROR',
      payload
    };
  };

  export const getLitigationTypeDetailListAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(getLitigationTypeDetailListRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/litigation-type-Detail-list'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(getLitigationTypeDetailListSuccess(res.data.data.litigationList));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(getLitigationTypeDetailListFailure('Something went wrong'));
          throw err;
        });
    };
  };

import CalendarManagement from 'components/common/calendar/CalendarManagement';
import NavbarTop from 'components/navbar/top/NavbarTop';
import TopNavRightSideNavItem from 'components/navbar/top/TopNavRightSideNavItem';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Moment from "moment";
import { getScheduleCaseDateListAPI, scheduleCaseDateListAction } from 'actions/Master/masterAction';
import ClientListCard from 'components/common/Card/ClientListCard';

const Dashboard = (data) => {
    const dispatch = useDispatch();
    const [monthsArray, setMonthsArray] = useState([
      { month: Moment().month() + 1, year: Moment().year() },
      { month: Moment().month() + 2, year: Moment().year() },
      { month: Moment().month() + 3, year: Moment().year() },
    ]);
  var roles = JSON.parse(localStorage.getItem('UserRoles'));
  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
	const headers = {
		Authorization: `Bearer ${JSON.parse(token).value}`
	};
    
    const masterReducer = useSelector((state) => state.rootReducer.masterReducer)
    var scheduleCaseMonth = masterReducer.scheduleCaseDateList;
    var scheduleCaseSelectDate = masterReducer.scheduleCaseSelectDate;
    
  useEffect(() => {
    {roles == "ADVOCATE" &&
    fetchScheduleCaseDateList(monthsArray) }
  }, [scheduleCaseSelectDate])

    const fetchScheduleCaseDateList = async (monthArray) => {
      const requestData = {
        encryptedAdvocateCode: localStorage.getItem("EncryptedAdvocateCode"),
        caseMonths: monthArray ? monthArray.map((item) => item.month) :  monthsArray.map((item) => item.month),
        caseYears: [...new Set(monthsArray.map((item) => item.year))],
      };
        await dispatch(
          getScheduleCaseDateListAPI(
            requestData,
            res => {
              if (res.data.scheduleCaseDateList.length > 0) {
             dispatch(scheduleCaseDateListAction(res.data.scheduleCaseDateList))
              } else {
             
                setTimeout(function () {
                  $('#no-case-schedule-message').html('No records found!');
                }, 100)
              }
            },
            err => {
              setTimeout(function () {
                $('#no-case-schedule-message').html('No records found!');
              }, 100)
              console.log(err?.data.message);
            },
            headers
          )
        );
      
    }
    const handleMonthsUpdate = (newMonthsArray) => {
      setMonthsArray(newMonthsArray);
      fetchScheduleCaseDateList(newMonthsArray);
    };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12} >
          {/* <h4></h4> */}
          <div className='d-flex justify-content-between navbar-top-height  navbar-dashboard' ><div className='navbarTop-onTop'><NavbarTop /></div>
            <div className='dashboard-dropdown-log'>
              <TopNavRightSideNavItem /> </div>
          </div>
          {roles == "ADVOCATE" && (
          <Row className="g-1 mt-0 mb-3 dasbhoard-row ">
            <Col sm={6} md={6} xl={6}  className='dashboard-col col-auto'>
            <CalendarManagement
               data={scheduleCaseMonth}
              //  monthsArray={monthsArray}  
               fetchData={(newMonthsArray) => handleMonthsUpdate(newMonthsArray)}
              />
              {/* <Greetings data={greetingItems} /> */}
              {/* <>Table 1</> */}
            </Col>
            <Col sm={6} md={6} xl={6} className='col-auto'>
            
              {/* <TeamProgress /> */}
              {/* <>Table 2</> */}
            </Col>
            <Col sm={6} md={6} className='client-card-b dashboard-col col-auto'  xl={6}>
              {/* Calendar */}
              <ClientListCard />
         
            </Col>
            <Col sm={6} className='ms-0 col-auto' md={4} xl={6}>
              {/* paragraph */}
            </Col>
          </Row> )
}

        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
import { combineReducers } from 'redux';
import treeViewReducer from './treeViewReducer';
import shortcutKeyReducer from './shortcutKeyReducer';
import securityUserAssignmentReducer from './SecurityUserAssignment/SecurityUserAssignmentReducer';
import SecurityMenuMenuTreeReducer from './SecurityMenuTree/SecurityMenuTreeReducer';
import advocateDetailsErrorReducer from './AdvocateRegistration/advocateDetailsErrorReducer';
import advocateDataReducer from './AdvocateRegistration/advocateDataReducer';
import advocateDetailsReducer from './AdvocateRegistration/advocateDetailsReducer';
import formChangedReducer from './formChangedReducer';
import tabInfoReducer from './tabinfoReducer';
import userDetailsErrorReducer from './SecurityUserAssignment/userDetailsErrorReducer';
import advocateRegistrationReducer from './AdvocateRegistration/advocateRegistrationReducer';
import clientRegistrationReducer from './ClientRegistration/clientRegistrationReducer';
import clientDetailsErrorReducer from './ClientRegistration/clientDetailsErrorReducer';
import advocateClientCaseReducer from './AdvocateClientCase/AdvocateClientCaseReducer';
import caseTypeMasterReducer from './CaseTypeMaster/caseTypeMasterReducer';
import advocateClientCaseDetailsErrorReducer from './AdvocateClientCase/advocateClientCaseDetailsErrorReducer.js';
import courtMasterReducer from './CourtMaster/courtMasterReducer';
import caseNextDateDetailsErrorReducer from './CaseNextDate/caseNextDateErrorReducer';
import caseNextDateReducer from './CaseNextDate/caseNextDateReducer';
import actionDetailsErrorReducer from './ActionDetails/actionDetailErrorReducer';
import actionDetailsReducer from './ActionDetails/actionDetailsReducer';
import filePlacementReducer from './AdvocateClientCase/FilePlacement/filePlacementReducer';
import clientTransactionDetailsReducer from './ClientTransactionDetails/clientTransactionDetailReducer';
import clientTransactionDetailsErrorReducer from './ClientTransactionDetails/clientTransactionDetailsErrorReducer';
import advocateDealingReducer from './AdvocateDealing/advocateDealingReducer';
import litigationTypesMasterReducer from './LitigationTypesMaster/litigationTypesMasterReducer';
import securityRoleMasterErrorReducer from './SecurityRoleMaster/securityRoleMasterErrorReducer';
import securityRoleMasterReducer from './SecurityRoleMaster/securityRoleMasterReducer';
import selectedMenusReducer from './SecurityRoleMenuAsscociation/selectedMenusReducer';
import countryMasterReducer from './CountryMaster/countryMasterReducer';
import stateMasterReducer from './StateMaster/stateMasterReducer';
import countryMasterErrorReducer from './CountryMaster/countryMasterErrorReducer';
import stateMasterErrorReducer from './StateMaster/stateMasterErrorReducer';
import courtTypeMasterReducer from './CourtTypeMaster/courtTypeMasterReducer';
import courtTypeMasterErrorReducer from './CourtTypeMaster/courtTypeMasterErrorReducer';
import menuDetailsErrorReducer from './SecurityMenuTree/menuDetailsErrorReducer';
import courtMasterErrorReducer from './CourtMaster/courtMasterErrorReducer';
import caseScheduleReducer from './CaseSchedule/caseScheduleReducer';
import caseTypeMasterErrorReducer from './CaseTypeMaster/caseTypeMasterErrorReducer';
import litigationTypeMasterErrorReducer from './LitigationTypesMaster/litigationTypeMasterErrorReducer';
import litigationTypesDetailReducer from './LitigationTypesDetail/litigationTypesDetailReducer';
import litigationTypeDetailErrorReducer from './LitigationTypesDetail/litigationTypeDetailErrorReducer';
import masterReducer from './MasterReducer/masterReducer';

const rootReducer = combineReducers({
  treeViewReducer,
  shortcutKeyReducer,
  securityUserAssignment: securityUserAssignmentReducer,
  securityMenuTree: SecurityMenuMenuTreeReducer,
  advocateDetailsErrorReducer,
  advocateDataReducer,
  advocateDetailsReducer,
  advocateRegistrationReducer,
  formChangedReducer,
  tabInfoReducer,
  userDetailsErrorReducer,
  clientRegistrationReducer,
  clientDetailsErrorReducer,
  advocateClientCaseReducer,
advocateClientCaseDetailsErrorReducer,
  caseTypeMasterReducer,
  courtMasterReducer,
  caseNextDateDetailsErrorReducer,
  caseNextDateReducer,
  actionDetailsErrorReducer,
  actionDetailsReducer,
  filePlacementReducer,
  clientTransactionDetailsReducer,
  clientTransactionDetailsErrorReducer,
 advocateDealingReducer,
 litigationTypesMasterReducer,
 securityRoleMasterErrorReducer,
 securityRoleMasterReducer,
 selectedMenusReducer,
 countryMasterReducer,
 stateMasterReducer,
 countryMasterErrorReducer,
 stateMasterErrorReducer,
 courtTypeMasterReducer,
 courtTypeMasterErrorReducer,
 menuDetailsErrorReducer,
 courtMasterErrorReducer,
 caseScheduleReducer,
 caseTypeMasterErrorReducer,
 litigationTypeMasterErrorReducer,
 litigationTypesDetailReducer,
 litigationTypeDetailErrorReducer,
 masterReducer
});

export default rootReducer;
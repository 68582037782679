/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import AdvanceTableFilter from '../../common/advance-table/AdvanceTableFilter';
import FalconComponentCard from '../FalconComponentCard';
import AdvanceTable1 from './AdvanceTable1';
import AdvanceTableFooter1 from './AdvanceTableFooter1';
import AdvanceTableSearchBox1 from './AdvanceTableSearchBox1';
import AdvanceTableWrapper1 from './AdvanceTableWrapper1';

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const AdvanceTableComponent1 = ({
  columns,
  module,
  data,
  filterOptions,
  filterName,
  handleFilterChange,
  filterValue,
  filterOptions1,
  filterName1,
  handleFilterChange1,
  filterValue1,
  yearList,
  yearFilterName,
  handleYearChange,
  selectedYear,
  fetchList,
  dataCount,
  pageSize,
  searchText,
  setSearchTextValue
}) => {
  // console.log('table component list', data);

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSizeState, setPageSizeState] = useState(pageSize);
  const [pageLength, setPageLength] = useState(15);
  const canPreviousPage = pageIndex > 1;
  const canNextPage = pageIndex < Math.ceil(dataCount / pageLength);

  const nextPage = () => {
    if (canNextPage) {
      setPageIndex(pageIndex + 1);
      fetchList(pageIndex + 1, pageLength, searchText);
    }
  };

  const previousPage = () => {
    if (canPreviousPage) {
      setPageIndex(pageIndex - 1);
      fetchList(pageIndex - 1, pageLength, searchText);
    }
  };

  const handleSetPageSize = size => {
    console.log('size', size);
    setPageSizeState(Number(size));
    setPageLength(Number(size));
    setPageIndex(1); // Reset to the first page
    fetchList(1, Number(size), searchText);    
  };

  const debouncedFetchList = useCallback(
    debounce((page, size, searchText) => {
      fetchList(page, size, searchText);
    }, 500), // 2-second debounce
    []
  );

  const handleSearchText = searchText => {
    setSearchTextValue(searchText);
    setPageIndex(1); // Reset to the first page
    debouncedFetchList(1, pageLength, searchText);
  };

  return (
    <AdvanceTableWrapper1
      columns={columns}
      data={data.length > 0 ? data : []}
      sortable
      pagination
      // perPage={dataCount}
      setPageSize={handleSetPageSize}
    >
      <FalconComponentCard id="TableSearchPanelCard" className="no-pad mb-1">
        <FalconComponentCard.Body>
          <Row className="mt-1 mb-1 space-between-row">
            <Col xs="auto" className="ms-1 me-1 flex-col" sm={6} lg={4}>
              <div className="text-left">
                <AdvanceTableFilter
                  options={filterOptions}
                  filterName={filterName}
                  handleFilterChange={handleFilterChange}
                  filterValue={filterValue}
                  module={module}
                />
              </div>
            </Col>
            <Col xs="auto" className="me-1 ms-minus-90 flex-col" sm={5} lg={3}>
              <div className="text-left">
                <AdvanceTableFilter
                  options={filterOptions1}
                  filterName={filterName1}
                  handleFilterChange={handleFilterChange1}
                  filterValue={filterValue1}
                  module={module}
                />
              </div>
            </Col>
            <Col xs="auto" className="me-1 flex-col" sm={6} lg={4}>
              <div className="text-right">
                <AdvanceTableSearchBox1
                  table
                  handleSearchText={handleSearchText}
                  searchText={searchText}
                />
              </div>
            </Col>
          </Row>
        </FalconComponentCard.Body>
      </FalconComponentCard>

      <FalconComponentCard className="list-card mb-1">
        <FalconComponentCard.Body>
          <AdvanceTable1
            table
            headerClassName="custom-bg-200 text-900 text-nowrap align-middle"
            style="padding-top : 0px"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'mb-0 overflow-hidden tab-page-table',
              responsive: true
            }}
          />
        </FalconComponentCard.Body>
      </FalconComponentCard>

      <FalconComponentCard id="TableFooterPanel">
        <FalconComponentCard.Body className="footer-pagination">
          <div className="mt-3 advance-table-footer">
            <AdvanceTableFooter1
              fetchList={fetchList}
              rowCount={dataCount}
              // page={data.slice(
              //   pageIndex * pageLength,
              //   (pageIndex + 1) * pageLength
              // )}
              pageSize={pageLength}
              pageIndex={pageIndex}
              setPageSize={handleSetPageSize}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </AdvanceTableWrapper1>
  );
};

export default AdvanceTableComponent1;

import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const CASE_TYPE_MASTER_LIST_REQUEST = 'CASE_TYPE_MASTER_LIST_REQUEST';
export const CASE_TYPE_MASTER_LIST_SUCCESS = 'CASE_TYPE_MASTER_LIST_SUCCESS';
export const CASE_TYPE_MASTER_LIST_FAILURE = 'CASE_TYPE_MASTER_LIST_FAILURE';
export const ADD_CASE_TYPE_MASTER_REQUEST = 'ADD_CASE_TYPE_MASTER_REQUEST';
export const ADD_CASE_TYPE_MASTER_SUCCESS = 'ADD_CASE_TYPE_MASTER_SUCCESS';
export const ADD_CASE_TYPE_MASTER_FAILURE = 'ADD_CASE_TYPE_MASTER_FAILURE';
export const UPDATE_CASE_TYPE_MASTER_REQUEST = 'UPDATE_CASE_TYPE_MASTER_REQUEST';
export const UPDATE_CASE_TYPE_MASTER_SUCCESS = 'UPDATE_CASE_TYPE_MASTER_SUCCESS';
export const UPDATE_CASE_TYPE_MASTER_FAILURE = 'UPDATE_CASE_TYPE_MASTER_FAILURE';

// caseTypeMasterList
export const caseTypeMasterListRequest = () => ({
    type: CASE_TYPE_MASTER_LIST_REQUEST
  });
  export const caseTypeMasterListSuccess = payload => ({
    type: CASE_TYPE_MASTER_LIST_SUCCESS,
    payload
  });
  export const caseTypeMasterListFailure = payload => ({
    type: CASE_TYPE_MASTER_LIST_FAILURE,
    payload
  });

  // AddCaseTypeMaster
  export const AddCaseTypeMasterRequest = () => ({
    type: ADD_CASE_TYPE_MASTER_REQUEST
  });
  export const AddCaseTypeMasterSuccess = payload => ({
    type: ADD_CASE_TYPE_MASTER_SUCCESS,
    payload
  });
  export const AddCaseTypeMasterFailure = payload => ({
    type: ADD_CASE_TYPE_MASTER_FAILURE,
    payload
  });

  // updateCaseTypeMaster
  export const updateCaseTypeMasterRequest = () => ({
    type: UPDATE_CASE_TYPE_MASTER_REQUEST
  });
  export const updateCaseTypeMasterSuccess = payload => ({
    type: UPDATE_CASE_TYPE_MASTER_SUCCESS,
    payload
  });
  export const updateCaseTypeMasterFailure = payload => ({
    type: UPDATE_CASE_TYPE_MASTER_FAILURE,
    payload
  });
  
  export const caseTypeMasterDataDetailAction = payload => {
    return {
      type: 'CASETYPEMASTERDETAIL',
      payload
    };
  };
  
  export const caseTypeMasterDetailErrorAction = payload => {
    return {
      type: 'CASETYPEMASTERDETAILSERROR',
      payload
    };
  };

  export const caseTypeMasterListAPI = (
    payload,
    successData,
    errorMSg,
    headers = {}
  ) => {
    return dispatch => {
      dispatch(caseTypeMasterListRequest());
      const url = `${REACT_APP_API_URL}/get-case-type-master-list`;
      return request('POST', url, payload, '', headers)
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(caseTypeMasterListSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(caseTypeMasterListFailure('Something went wrong'));
          throw err;
        });
    };
  };

  export const addCaseTypeMasterAPI = (
    payload,
    successData,
    errorMSg,
    headers = {}
  ) => {
    return dispatch => {
      dispatch(AddCaseTypeMasterRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/add-case-type-master'}`,
        payload,
        '',
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(AddCaseTypeMasterSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(AddCaseTypeMasterFailure('Something went wrong'));
          throw err;
        });
    };
  };

  export const updateCaseTypeMasterAPI = (
    payload,
    successData,
    errorMSg,
    headers = {}
  ) => {
    return dispatch => {
      dispatch(updateCaseTypeMasterRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/update-case-type-master'}`,
        payload,
        '',
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(updateCaseTypeMasterSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(updateCaseTypeMasterFailure('Something went wrong'));
          throw err;
        });
    };
  };
import {
  GET_LITIGATION_TYPES_MASTER_LIST_REQUEST,
  GET_LITIGATION_TYPES_MASTER_LIST_SUCCESS,
  GET_LITIGATION_TYPES_MASTER_LIST_FAILURE,
  ADD_LITIGATION_TYPES_MASTER_FAILURE,
  ADD_LITIGATION_TYPES_MASTER_REQUEST,
  ADD_LITIGATION_TYPES_MASTER_SUCCESS

} from "actions/LitigationTypesMaster/litigationTypesMasterAction";
const initialState = {
  litigationList: [],
  litigationTypeMaster: {},
  litigationTypeMasterData: {},

};

export default function litigationTypesMasterReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_LITIGATION_TYPES_MASTER_LIST_REQUEST:
      return {
        ...state
      };
    case GET_LITIGATION_TYPES_MASTER_LIST_SUCCESS:
      return {
        ...state,
        litigationList: action.payload
      };
    case GET_LITIGATION_TYPES_MASTER_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case ADD_LITIGATION_TYPES_MASTER_REQUEST:
      return {
        ...state,
      };
    case ADD_LITIGATION_TYPES_MASTER_SUCCESS:
      return {
        ...state,
        litigationTypeMasterData: action.payload
      };
    case ADD_LITIGATION_TYPES_MASTER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
      case 'LITIGATIONDETAIL':
        if (!action.payload) {
            return initialState;
        } else {
            return {
                ...state,
                litigationTypeMaster: action.payload
            };
        }
    case 'LITIGATIONTYEMASTERDATA':
        if (!action.payload) {
            return initialState;
        } else {
            return {
                ...state,
                litigationTypeMasterData: action.payload
            };
        }
    default:
      return state;
  }
}
import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Spinner, Modal, Button } from 'react-bootstrap';
import {
  advocateDetailsAction,
  advocateDetailsErrorAction,
  formChangedAction
} from 'actions';
import { advocateUpdateDetailsAPI } from 'actions/AdvocateRegistration/advocateRegistrationAction';

const tabArray = ['Profile'];

export const Profile = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');

  const advocateDetailsReducer = useSelector(
    state => state.rootReducer.advocateDetailsReducer
  );
  const advocateData = advocateDetailsReducer.advocateDetails;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );

  const [formHasError, setFormError] = useState(false);

  useEffect(() => {
    dispatch(
      advocateDetailsAction({
        ...advocateData,
        encryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode')
      })
    );
  }, []);

  useEffect(() => {
    $('#btnNew').hide();
    $('#btnSave').show();
  }, []);

  $('[data-rr-ui-event-key*="Profile"]')
    .off('click')
    .on('click', function () {
      $('#btnSave').show();
      $('#btnSave').attr('disabled', true);
      $('#btnNew').hide();
    });

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      window.location.href = '/dashboard';
    }
  };

  const advocateValidation = () => {
    const advocateNameErr = {};
    const countryErr = {};
    const stateErr = {};
    const chamberNoErr = {};
    const advocatePicFileErr = {};
    const advocateDealingErr = {};
    const practiceSinceErr = {};

    const maxFileSizeInBytes = 2 * 1024 * 1024;

    let isValid = true;
    let isAdvocateValid = true;

    if (!advocateData.chamberNo) {
      chamberNoErr.chamberNoEmpty = 'Enter chamber no';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (!advocateData.countryCode) {
      countryErr.empty = 'Select country';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (advocateData.practiceSince) {
      const currentYear = new Date().getFullYear();
      const practiceYear = new Date(advocateData.practiceSince).getFullYear();

      if (practiceYear > currentYear) {
        practiceSinceErr.empty =
          'Since date should be less than or equal to the current year';
        isValid = false;
        isAdvocateValid = false;
        setFormError(true);
      }
    }

    if (!advocateData.stateCode) {
      stateErr.empty = 'Select state';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (
      advocateData.advocatePicFile != null &&
      advocateData.advocatePicFile != ''
    ) {
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg'];
      const fileExtension = (advocateData.advocatePicFile.name || '')
        .split('.')
        .pop()
        .toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        advocatePicFileErr.FileImgInvalid =
          'Invalid file extension. Please upload a file with extensions jpg, jpeg, png, or svg';
        isValid = false;
        isAdvocateValid = false;
        setFormError(true);
      }
      if (advocateData.advocatePicFile.size > maxFileSizeInBytes) {
        advocatePicFileErr.FileImgSize =
          'File size exceeds the maximum allowed 2 MB';
        isValid = false;
        isAdvocateValid = false;
        setFormError(true);
      }
    }

    if (!isAdvocateValid) {
      if (!$('[data-rr-ui-event-key*="Advocate Details"]').hasClass('active')) {
        $('[data-rr-ui-event-key*="Advocate Details"]').trigger('click');
      }
    }
    if (!isValid) {
      var errorObject = {
        advocateNameErr,
        countryErr,
        stateErr,
        chamberNoErr,
        advocatePicFileErr,
        advocateDealingErr,
        practiceSinceErr
      };

      dispatch(advocateDetailsErrorAction(errorObject));
    }

    return isValid;
  };

  const updateCallback = (isAddAdvocate = false) => {
    setModalShow(false);
    dispatch(advocateDetailsErrorAction(undefined));
    dispatch(formChangedAction(undefined));
    if (!isAddAdvocate) {
      toast.success('Advocate details updated successfully!', {
        theme: 'colored'
      });
    }
  };

  const updateAdvocateDetails = async () => {
    const headers = {
      Authorization: `Bearer ${JSON.parse(token).value}`
    };
    if (advocateValidation()) {
      const formData = new FormData();
      formData.append('IsProfile', true);
      formData.append(
        'EncryptedAdvocateCode',
        advocateData.encryptedAdvocateCode
      );
      formData.append('AdvocateName', advocateData.advocateName.toUpperCase());
      formData.append(
        'AdvocateAddress1',
        advocateData.advocateAddress1
          ? advocateData.advocateAddress1.toUpperCase()
          : ''
      );
      formData.append(
        'AdvocateAddress2',
        advocateData.advocateAddress2
          ? advocateData.advocateAddress2.toUpperCase()
          : ''
      );
      formData.append(
        'AdvocateAddress3',
        advocateData.advocateAddress3
          ? advocateData.advocateAddress3.toUpperCase()
          : ''
      );
      formData.append('GSTNo', advocateData.gstNo ? advocateData.gstNo : '');
      formData.append('CountryCode', advocateData.countryCode);
      formData.append('StateCode', advocateData.stateCode);
      formData.append('NoOfUsers', advocateData.noOfUsers);
      formData.append(
        'PracticingSince',
        advocateData.practiceSince
          ? advocateData.practiceSince.toUpperCase()
          : ''
      );
      formData.append('ChamberNo', advocateData.chamberNo);
      formData.append(
        'landlineNo',
        advocateData.landlineNo ? advocateData.landlineNo : ''
      );
      formData.append(
        'NeedSEO',
        advocateData.needSEO != null ? advocateData.needSEO.toUpperCase() : ''
      );
      formData.append(
        'ClientProfile',
        advocateData.clientProfile
          ? advocateData.clientProfile.toUpperCase()
          : ''
      );
      formData.append(
        'ContactPerson',
        advocateData.contactPerson
          ? advocateData.contactPerson.toUpperCase()
          : ''
      );
      formData.append(
        'AdvocatePictureFile',
        advocateData.advocatePicFile ? advocateData.advocatePicFile : null
      );
      formData.append(
        'IsDeleteAdvocatePicFile',
        advocateData.IsDeleteAdvocatePicFile
          ? advocateData.IsDeleteAdvocatePicFile
          : false
      );
      formData.append(
        'modifyUser',
        localStorage.getItem('LoginUserName').toUpperCase()
      );

      var hasError = false;
      setIsLoading(true);
      if (formChangedData.advocateUpdate) {
        await dispatch(
          advocateUpdateDetailsAPI(
            formData,
            res => {
              setIsLoading(false);
              setModalShow(false);
            },
            err => {
              setIsLoading(false);
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
              hasError = true;
            },
            headers
          )
        );
      }
      if (!hasError) {
        updateCallback();
      }
    }
  };

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true') {
      window.location.href = '/dashboard';
    }
    setModalShow(false);
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={updateAdvocateDetails}>
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}
            >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <TabPage
        tabArray={tabArray}
        module="Profile"
        saveDetails={updateAdvocateDetails}
        exitModule={exitModule}
      />
    </>
  );
};

export default Profile;
import { CASE_TYPE_MASTER_LIST_REQUEST,
    CASE_TYPE_MASTER_LIST_SUCCESS,
    CASE_TYPE_MASTER_LIST_FAILURE,
    ADD_CASE_TYPE_MASTER_REQUEST,
    ADD_CASE_TYPE_MASTER_SUCCESS,
    ADD_CASE_TYPE_MASTER_FAILURE,
    UPDATE_CASE_TYPE_MASTER_REQUEST,
    UPDATE_CASE_TYPE_MASTER_SUCCESS,
    UPDATE_CASE_TYPE_MASTER_FAILURE
 } from "actions/CaseTypeMaster/caseTypeMasterAction";

 const initialState = {
    caseTypeList: [],
    caseTypeMasterDetail: {},
    caseTypeMasterData: {}
  };
  
  export default function caseTypeMasterReducer(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case CASE_TYPE_MASTER_LIST_REQUEST:
        return {
          ...state
        };
      case CASE_TYPE_MASTER_LIST_SUCCESS:
        return {
          ...state,
          caseTypeList: action.payload
        };
      case CASE_TYPE_MASTER_LIST_FAILURE:
        return {
          ...state,
          error: action.payload
        };
        case ADD_CASE_TYPE_MASTER_REQUEST:
      return {
        ...state
      };
    case ADD_CASE_TYPE_MASTER_SUCCESS:
      return {
        ...state,
        caseTypeMasterData: action.payload
      };
    case ADD_CASE_TYPE_MASTER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
      case UPDATE_CASE_TYPE_MASTER_REQUEST:
        return {
          ...state
        };
      case UPDATE_CASE_TYPE_MASTER_SUCCESS:
        return {
          ...state,
          caseTypeMasterData: action.payload
        };
      case UPDATE_CASE_TYPE_MASTER_FAILURE:
        return {
          ...state,
          error: action.payload
        };
      case 'CASETYPEMASTERDETAIL':
        if (!action.payload) {
          return initialState;
        } else {
          return {
            ...state,
            caseTypeMasterDetail: action.payload
          };
        }
      case 'CASETYPEMASTERDATA':
        if (!action.payload) {
          return initialState;
        } else {
          return {
            ...state,
            caseTypeMasterData: action.payload
          };
        }
      default:
        return state;
    }
  }
import PropTypes from 'prop-types';
import { Badge, Button, Modal, Table,Form } from 'react-bootstrap';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '../IconButton';
import $ from 'jquery';
import { advocateDetailsAction } from 'actions';
import { userDetailsAction } from 'actions/SecurityUserAssignment/SecurityUserAssignmentAction';
import { clientDetailsAction } from 'actions/ClientRegistration/ClientRegistrationAction';
import {
  advocateClientCaseDataAction,
  caseDeletedSuccessAction,
  deleteAdvocateClientCaseAPI,
  resetCaseDeletedAction
} from 'actions/AdvocateClientCase/AdvocateClientCaseAction';
import { toast } from 'react-toastify';
import { filePlacementDataAction } from 'actions/AdvocateClientCase/FilePlacement/filePlacementAction';
import {
  clientTransactionListAPI,
  transactionDetailsAction
} from 'actions/ClientTransactionDetails/clientTransactionDetailsAction';
import { roleDetailAction } from 'actions/SecurityRoleMaster/securityRoleMasterAction';
import { countryDeletedSuccessAction, countryDetailAction, deleteCountryMasterDetailAPI, resetCountryDeletedAction } from 'actions/CountryMaster/countryMasterAction';
import { courtTypeDataDetailAction } from 'actions/CourtTypeMaster/courtTypeMasterAction';
import { courtMasterDataDetailAction } from 'actions/CourtMaster/courtMasterAction';
import { caseTypeMasterDataDetailAction } from 'actions/CaseTypeMaster/caseTypeMasterAction';
import { litigationDetailAction } from 'actions/LitigationTypesMaster/litigationTypesMasterAction';

const AdvanceTable1 = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps
}) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [paramsData, setParamsData] = useState({});
  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const header = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const toTabPage = rowData => {
    if (rowData.hasOwnProperty('encryptedUserId')) {
      dispatch(userDetailsAction(rowData));
      $('[data-rr-ui-event-key*="User Details"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="User Details"]').trigger('click');
      $('#btnSave').attr('disabled', true);
    } else if (rowData.hasOwnProperty('encryptedClientCode') && rowData.encryptedClientCode) {
      localStorage.setItem(
        'EncryptedResponseClientCode',
        rowData.encryptedClientCode
      );
      dispatch(clientDetailsAction(rowData));
      $('[data-rr-ui-event-key*="Client Details"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Client Details"]').trigger('click');
      $('#btnSave').attr('disabled', true);
    } else if (
      rowData.hasOwnProperty('encryptedCaseId') &&
      rowData.hasOwnProperty('nextHearingDate')
    ) {
      dispatch(advocateClientCaseDataAction(rowData));
      $('[data-rr-ui-event-key*="Case Details"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Case Details"]').trigger('click');
      $('#btnSave').attr('disabled', true);
      localStorage.setItem('EncryptedResponseCaseId', rowData.encryptedCaseId);
      localStorage.setItem('OldCaseStatus', rowData.caseStatus);
    } else if (
      rowData.hasOwnProperty('encryptedCaseId') &&
      rowData.hasOwnProperty('clientCaseNo')
    ) {
      dispatch(filePlacementDataAction(rowData));
      $('[data-rr-ui-event-key*="File Placement Details"]').attr(
        'disabled',
        false
      );
      $('[data-rr-ui-event-key*="File Placement Details"]').trigger('click');
      $('#btnSave').attr('disabled', true);
      localStorage.setItem('EncryptedResponseCaseId', rowData.encryptedCaseId);
    }
    else if (rowData.hasOwnProperty('encryptedRoleId') && rowData.hasOwnProperty('roleId')) {
      dispatch(roleDetailAction(rowData));
      $('[data-rr-ui-event-key*="Add Role"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Add Role"]').trigger('click');
      $('#btnSave').attr('disabled', true);
      localStorage.setItem(
        'EncryptedResponseRoleId',
        rowData.encryptedRoleId
      );
    }
    else if (rowData.hasOwnProperty('encryptedCourtCode') && rowData.hasOwnProperty('courtCode')) {
      dispatch(courtMasterDataDetailAction(rowData));
      $('[data-rr-ui-event-key*="Add Court"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Add Court"]').trigger('click');
      $('#btnSave').attr('disabled', true);
      localStorage.setItem(
        'EncryptedResponseCourtCode',
        rowData.encryptedCourtCode
      );
    }
    else if (rowData.hasOwnProperty('encryptedRoleId') && rowData.hasOwnProperty('roleId')) {
      dispatch(roleDetailAction(rowData));
      $('[data-rr-ui-event-key*="Add Role"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Add Role"]').trigger('click');
      $('#btnSave').attr('disabled', true);
      localStorage.setItem(
        'EncryptedResponseRoleId',
        rowData.encryptedRoleId
      );
    }
    else if (rowData.hasOwnProperty('encryptedCountryCode') && rowData.hasOwnProperty('countryCode')) {
      dispatch(countryDetailAction(rowData));
      localStorage.removeItem('DeleteStateCodes');
      $('[data-rr-ui-event-key*="Add Country"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Add Country"]').trigger('click');
      $('#btnSave').attr('disabled', true);
      localStorage.setItem(
        'EncryptedResponseCountryCode',
        rowData.encryptedCountryCode
      );
    }
    else if (rowData.hasOwnProperty('encryptedCourtTypeCode') && rowData.hasOwnProperty('courtTypeCode')) {
      dispatch(courtTypeDataDetailAction(rowData));
      $('[data-rr-ui-event-key*="Add Court Type"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Add Court Type"]').trigger('click');
      $('#btnSave').attr('disabled', true);
      localStorage.setItem(
        'EncryptedResponseCourtTypeCode',
        rowData.encryptedCourtTypeCode
      );
    }
    else if (rowData.hasOwnProperty('encryptedAdvocateCode') && rowData.hasOwnProperty('advocateCode') && rowData.hasOwnProperty('noOfUsers')) {
      dispatch(advocateDetailsAction(rowData));
      $('[data-rr-ui-event-key*="Details"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Advocate Dealing"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Advocate Details"]').trigger('click');
      $('#btnSave').attr('disabled', true);
      localStorage.setItem(
        'EncryptedResponseAdvocateCode',
        rowData.encryptedAdvocateCode
      );
      getTransactionDetailsList(rowData.encryptedAdvocateCode);
    }
       else if (rowData.hasOwnProperty('litigationCode') && rowData.hasOwnProperty('encryptedLitigationCode')) {
         dispatch(litigationDetailAction(rowData));
         $('[data-rr-ui-event-key*="Add Litigation Type Detail"]').attr('disabled', false);
         $('[data-rr-ui-event-key*="Add Litigation Type Detail"]').trigger('click');
         $('#btnSave').attr('disabled', true);
         localStorage.setItem(
           'EncryptedResponseLitigationTypeMasterCode',
           rowData.encryptedLitigationCode
         );
       }

       else if (rowData.hasOwnProperty('encryptedCaseCode') && rowData.hasOwnProperty('caseCode')) {
        dispatch(caseTypeMasterDataDetailAction(rowData));
        $('[data-rr-ui-event-key*="Add Case Type"]').attr('disabled', false);
        $('[data-rr-ui-event-key*="Add Case Type"]').trigger('click');
        $('#btnSave').attr('disabled', true);
        localStorage.setItem(
          'EncryptedResponseCaseCode',
          rowData.encryptedCaseCode
        );
      }
  };
  
  const ModalPreview = (valueType, ValueId ) => {
    setModalShow(true);
    setParamsData({valueType,ValueId});
  };

  var handleChange = async (paramsData)=>{
  if(paramsData.valueType == 'caseId'){
      handleDeleteAdvocateClientCase(paramsData.ValueId)
    }else if(paramsData.valueType == 'countryCode'){
      handleDeleteCountry(paramsData.ValueId)
    }
  }

  var handleDeleteAdvocateClientCase = async encryptedCaseId => {
    var request = {
      EncryptedCaseId: encryptedCaseId
    };
    dispatch(
      deleteAdvocateClientCaseAPI(
        request,
        res => {
          toast.success(res?.data?.message, {
            theme: 'colored',
            autoClose: 10000
          });
          setModalShow(false);
          if (res?.data.status === 200) {
            dispatch(caseDeletedSuccessAction(true));
          }
        },
        err => {
          toast.error(err?.data?.message, {
            theme: 'colored',
            autoClose: 10000
          });
          dispatch(resetCaseDeletedAction(false));
          setModalShow(false);
        },
        header
      )
    );
  };

  var handleDeleteCountry = async encryptedCountryCode => {
    var request = {
      EncryptedCountryCode: encryptedCountryCode
    };
    dispatch(
      deleteCountryMasterDetailAPI(
        request,
        res => {
          toast.success(res?.data?.message, {
            theme: 'colored',
            autoClose: 10000
          });
          setModalShow(false);
          if (res?.data.status === 200) {
            dispatch(countryDeletedSuccessAction(true));
          }
        },
        err => {
          toast.error(err?.data?.message, {
            theme: 'colored',
            autoClose: 10000
          });
           dispatch(resetCountryDeletedAction(false));
          setModalShow(false);
        },
        header
      )
    );
  
  };

  const getTransactionDetailsList = async advocateCode => {
    const requestParams = {
      EncryptedAdvocateCode: localStorage.getItem(
        'EncryptedResponseAdvocateCode'
      )
        ? localStorage.getItem('EncryptedResponseAdvocateCode')
        : advocateCode
    };
    dispatch(
      clientTransactionListAPI(
        requestParams,

        res => {
          if ($('#TransactionDetailsTable tbody tr').length > 1) {
            $('#TransactionDetailsTable tbody tr').remove();
          }
          let transactionDetailsData = [];
          transactionDetailsData =
            res.data.data.length > 0 ? res.data.data : [];
          dispatch(transactionDetailsAction(transactionDetailsData));
          if (res.data && res.data.data.length > 0) {
            $('#TransactionDetailsTable').show();
            $('#TransactionDetailsListCard').show();
          } else {
            $('#TransactionDetailsTable').hide();
            $('#TransactionDetailsListCard').hide();
          }
        },
        err => {
          dispatch(transactionDetailsAction(undefined));
          toast.error(err?.data.message, {
            theme: 'colored',
            autoClose: 10000
          });
          $('#TransactionDetailsTable').hide();
        },
        header
      )
    );
  };

  return (
    <>
      {modalShow && paramsData && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {paramsData.valueType == 'caseId' ? (
            <h4>Are you sure, you want to delete this client case detail?</h4>) :
            (
              <h4>Are you sure, All states under this country will also be deleted?</h4>)
            }</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => setModalShow(false)}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => handleChange(paramsData)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Table id="advanceTable" {...getTableProps(tableProps)}>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}
              >
                {column.render('Header')}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                key={i}
                className={rowClassName}
                {...row.getRowProps()}
                onDoubleClick={() => toTabPage(row.original)}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <>
                      <td
                        key={index}
                        {...cell.getCellProps(cell.column.cellProps)}
                      >
                        {cell.column.id !== 'deleteCase' &&
                        cell.column.id !== 'caseStatus' &&
                        cell.column.id !== 'status' &&
                        cell.column.id !== 'approvalStatus' &&
                        cell.column.id !== 'poPrintStatus' &&
                        cell.column.id !== 'demandPrintStatus' &&
                        cell.column.id !== 'printStatus' &&
                        cell.column.id !== 'materialStatus' &&
                        cell.column.id !== 'poStatus' &&
                        cell.column.id !== 'invoiceStatus' &&
                        cell.column.id !== 'vendorInvoicePrintStatus' &&
                        cell.column.id !== 'receiveStatus' &&
                        cell.column.id !== 'deleteCountry' &&
                        cell.column.id !== 'activeStatus'  &&
                        cell.column.id !== 'deleteCase'  ? (
                          cell.render('Cell')
                        ) : cell.column.id == 'status' &&
                          cell.row.values.status == 'Active' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'status' &&
                          cell.row.values.status == 'Suspended' ? (
                          <Badge pill bg="secondary">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'approvalStatus' &&
                          cell.row.values.approvalStatus == 'Approved' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'approvalStatus' &&
                          cell.row.values.approvalStatus == 'Draft' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'approvalStatus' &&
                          cell.row.values.approvalStatus ==
                            'Send for Verification' ? (
                          <Badge pill bg="warning">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'approvalStatus' &&
                          cell.row.values.approvalStatus == 'Suspended' ? (
                          <Badge pill bg="danger">
                            {cell.render('Cell')}
                          </Badge>
                        ) 
                        : cell.column.id == 'activeStatus' ? (
                        <Form.Select id="txtStatus" col="1" name="status" value={ cell.row.values.activeStatus == "Active" ? "Active" : "Suspended"}
                        //  onChange={handleFieldChange}
                         >
                        <option value="Active">Active</option>
                        <option value="Suspended">Suspended</option>
                    </Form.Select>
                        ): cell.column.id == 'materialStatus' &&
                          cell.row.values.materialStatus == 'Approved' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'materialStatus' &&
                          cell.row.values.materialStatus == 'Draft' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'poStatus' &&
                          cell.row.values.poStatus == 'Approved' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'poStatus' &&
                          cell.row.values.poStatus == 'Draft' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'poStatus' &&
                          cell.row.values.poStatus == 'Rejected' ? (
                          <Badge pill bg="danger">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'poStatus' &&
                          cell.row.values.poStatus == 'Hold' ? (
                          <Badge pill bg="warning">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'poStatus' &&
                          cell.row.values.poStatus == 'Invoiced' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'poStatus' &&
                          cell.row.values.poStatus == 'Partially Paid' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'caseStatus' &&
                          cell.row.values.caseStatus == 'CLOSED' ? (
                          <Badge pill bg="secondary">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'caseStatus' &&
                          cell.row.values.caseStatus == 'LOST' ? (
                          <Badge pill bg="danger">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'caseStatus' &&
                          cell.row.values.caseStatus == 'ONGOING' ? (
                          <Badge pill bg="warning">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'caseStatus' &&
                          cell.row.values.caseStatus == 'WON' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'caseStatus' &&
                          cell.row.values.caseStatus == 'DISPOSED' ? (
                          <Badge pill bg="warning">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'printStatus' &&
                          cell.column.id == 'receiveStatus' &&
                          cell.row.values.receiveStatus == 'Not Received' ? (
                          <Badge pill bg="danger">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'printStatus' &&
                          cell.row.values.printStatus == 'Approved' ? (
                          <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="print"
                            iconClassName="me-1"
                            className="me-1 mb-2 mb-sm-0 hide-on-print"
                            // onClick={() => generatePdf(cell.row.original.farmerCode, cell.row.original.vendorCode, cell.row.original.encryptedMaterialReceiptId)}
                          >
                            Print
                          </IconButton>
                        ) : cell.column.id == 'poPrintStatus' &&
                          cell.row.values.poPrintStatus == 'Approved' ? (
                          <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="print"
                            iconClassName="me-1"
                            className="me-1 mb-2 mb-sm-0 hide-on-print"
                            // onClick={() => generatePdf('', '', '', cell.row.original.encryptedPoNo)}
                          >
                            Print
                          </IconButton>
                        ) : cell.column.id == 'demandPrintStatus' &&
                          cell.row.values.demandStatus == 'Approved' ? (
                          <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="print"
                            iconClassName="me-1"
                            className="me-1 mb-2 mb-sm-0 hide-on-print"
                            // onClick={() => generatePdf('', '', '', '', '', cell.row.original.encryptedDemandNo)}
                          >
                            Print
                          </IconButton>
                        ) : cell.column.id == 'vendorInvoicePrintStatus' &&
                          cell.row.values.vendorInvoicePrintStatus ==
                            'Approved' ? (
                          <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="print"
                            iconClassName="me-1"
                            className="me-1 mb-2 mb-sm-0 hide-on-print"
                            // onClick={() => generatePdf('', '', '', '', cell.row.original.encryptedInvoiceHeaderCode)}
                          >
                            Print
                          </IconButton>
                        ) : cell.column.id == 'deleteCase'  ? (
                          <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="trash"
                            iconClassName="me-1"
                            className="me-1 mb-2 mb-sm-0 hide-on-print"
                            onClick={() =>
                              ModalPreview("caseId", cell.row.original.encryptedCaseId  )
                            }
                          ></IconButton>
                        ) : cell.column.id == 'deleteCountry' ? (
                          <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="trash"
                            iconClassName="me-1"
                            className="me-1 mb-2 mb-sm-0 hide-on-print"
                            onClick={() =>
                              ModalPreview("countryCode", cell.row.original.encryptedCountryCode )
                            }
                          ></IconButton>
                        ) : cell.column.id == 'invoiceStatus' &&
                          cell.row.values.invoiceStatus == 'Approved' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'invoiceStatus' &&
                          cell.row.values.invoiceStatus == 'Draft' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'invoiceStatus' &&
                          cell.row.values.invoiceStatus == 'Rejected' ? (
                          <Badge pill bg="danger">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'invoiceStatus' &&
                          cell.row.values.invoiceStatus == 'Partially Paid' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'invoiceStatus' &&
                          cell.row.values.invoiceStatus == 'Fully Paid' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'demandStatus' &&
                          cell.row.values.demandStatus == 'Draft' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'demandStatus' &&
                          cell.row.values.demandStatus == 'Cancelled' ? (
                          <Badge pill bg="danger">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'demandStatus' &&
                          cell.row.values.demandStatus == 'Approved' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : (
                          ''
                        )}
                      </td>
                    </>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

setTimeout(() => {
  $('#advanceTable tbody tr').click(function () {
    if ($(this).hasClass('row-selected')) {
      return;
    }
    $('#advanceTable tr.row-selected').removeClass('row-selected');
    $(this).addClass('row-selected');
  });
}, 1000);

AdvanceTable1.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object
};

export default AdvanceTable1;

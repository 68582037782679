import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { formChangedAction } from 'actions';
import { addLitigationTypeMasterAPI, getLitigationTypeMasterListAPI, litigationDetailAction, litigationDetailsErrorAction } from 'actions/LitigationTypesMaster/litigationTypesMasterAction';
import TabPage1 from 'components/common/TabPage1';
import { getLitigationTypeDetailListAPI, litigationTypeDetailAction, litigationTypeDetailsErrorAction } from 'actions/LitigationTypesDetail/litigationTypesDetailAction';

const tabArray = ['Litigation List', 'Add Litigation Type Detail'];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'litigationCode', Header: 'Litigation Code' },
  { accessor: 'litigationDetailName', Header: 'Litigation Name' },
  { accessor: 'activeStatus', Header: 'Status' },
];

export const LitigationTypeMaster = () => {
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(15);
  const [pageSize, setPageSize] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [litigationTypeDetailsList, setLitigationTypeDetailsList] = useState([]);
  const [litigationTypeMasterList, setLitigationTypeMasterList] = useState([]);
  var responseLitigationTypeMasterCode = localStorage.getItem("EncryptedResponseLitigationTypeMasterCode")

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getLitigationList = (page, size = perPage, searchText) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      searchText: searchText ? searchText : "",
      isDropDown: false,
    };
    setIsLoading(true);
    dispatch(
      getLitigationTypeMasterListAPI(
        data,
        response => {
          setIsLoading(false);
          setLitigationTypeMasterList(response.data.data.litigationList);
          setTotalCount(response.data.data.totalCount);
        },
        err => {
          setIsLoading(false)
          setLitigationTypeMasterList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const getLitigationTypeDetailList = async (litigationCode, index) => {
    const data = {
      LitigationCode: litigationCode
    };
    setIsLoading(true);
    await dispatch(
      getLitigationTypeDetailListAPI(
        data,
        res => {
          setIsLoading(false);
          let litigationDetailData = [];

          if (res.data.data.litigationTypeDetailList.length > 0)
            res.data.data.litigationTypeDetailList.forEach(data => {
              litigationDetailData.push({
                key: data.litigationTypeDetailName,
                value: data.litigationDetailCode
              });
            });

          setLitigationTypeDetailsList(prevList => {
            const newLitigationDetailList = [...prevList];
            newLitigationDetailList[index] = litigationDetailData;
            return newLitigationDetailList;
          });
        },
        err => {
          setLitigationTypeDetailsList(prevList => {
            const newLitigationDetailList = [...prevList];
            newLitigationDetailList[index] = [];
            return newLitigationDetailList;
          });
          setIsLoading(false);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const litigationTypesMasterReducer = useSelector((state) => state.rootReducer.litigationTypesMasterReducer)
  var litigationTypeMasterData = litigationTypesMasterReducer.litigationTypeMaster;

  const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
  var formChangedData = formChangedReducer.formChanged;

  const litigationTypeMasterErrorReducer = useSelector((state) => state.rootReducer.litigationTypeMasterErrorReducer)
  const litigationTypeMasterErr = litigationTypeMasterErrorReducer.litigationTypeMasterError;

  const litigationTypesDetailReducer = useSelector((state) => state.rootReducer.litigationTypesDetailReducer)
  const litigationTypesDetailData = litigationTypesDetailReducer.litigationTypeDetail;


  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );

  const [formHasError, setFormError] = useState(false);
  const [activeTabName, setActiveTabName] = useState();


  useEffect(() => {
    $('[data-rr-ui-event-key*="Add Litigation Type Detail"]').attr('disabled', true);
  }, []);


  useEffect(() => {
    localStorage.removeItem('EncryptedResponseLitigationTypeMasterCode');
    getLitigationList(1, perPage)
  }, []);

  const clearLitigationTypeMasterReducers = () => {
    dispatch(litigationDetailAction(undefined));
    dispatch(litigationDetailsErrorAction(undefined));
    dispatch(litigationTypeDetailAction([]));
    dispatch(litigationTypeDetailsErrorAction(undefined));
    dispatch(formChangedAction(undefined));
  };

  $('[data-rr-ui-event-key*="Litigation List"]')
    .off('click')
    .on('click', function () {
      let isDiscard = $('#btnDiscard').attr('isDiscard');
      if (isDiscard != 'true' && isFormChanged) {
        setModalShow(true);
        localStorage.removeItem('EncryptedResponseLitigationTypeMasterCode');
        setTimeout(function () {
          $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
        }, 50);
      } else {
        $('#btnNew').show();
        $('#btnSave').hide();
        $('#btnCancel').hide();
        $('[data-rr-ui-event-key*="Add Litigation Type Detail"]').attr('disabled', true);
        $('[data-rr-ui-event-key*="Litigation List"]').attr('disabled', false);
        getLitigationList(1, perPage)
        localStorage.removeItem('EncryptedResponseLitigationTypeMasterCode');
        $('#btnDiscard').attr('isDiscard', false);
        clearLitigationTypeMasterReducers();
      }
    });

  $('[data-rr-ui-event-key*="Add Litigation Type Detail"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('Add Litigation Type Detail');
      $('#btnNew').hide();
      $('#btnSave').show();
      $('#btnCancel').show();
    });

  const newDetails = () => {
    $('[data-rr-ui-event-key*="Add Litigation Type Detail"]').attr('disabled', false);
    $('[data-rr-ui-event-key="Add Litigation Type Detail"]').trigger('click');
    $('#btnSave').attr('disabled', false);
    clearLitigationTypeMasterReducers();
  };

  const cancelClick = () => {
    $('#btnExit').attr('isExit', 'false');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      $('[data-rr-ui-event-key*="Litigation List"]').trigger('click');
    }
  };

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      window.location.href = '/dashboard';
    }
  };

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true') {
      window.location.href = '/dashboard';
    }
    else {
      $('[data-rr-ui-event-key*="List"]').trigger('click');
      setModalShow(false);
    }
  };

  const litigationTypeMasterValidation = () => {
    const litigationNameErr = {};
    const litigationTypesDetailNameErr = {};
    let isValid = true;

    if (!litigationTypeMasterData.litigationName) {
      litigationNameErr.nameEmpty = 'Enter litigation name';
      isValid = false;
      setFormError(true);
    }

    if (litigationTypesDetailData.length < 1) {
      litigationTypesDetailNameErr.stateEmpty = "At least one litigation type detail required";
      setTimeout(() => {
        toast.error(litigationTypesDetailNameErr.stateEmpty, {
          theme: 'colored'
        });
      }, 1000);
      isValid = false;
      setFormError(true);
    } else if (litigationTypesDetailData && litigationTypesDetailData.length > 0) {
      litigationTypesDetailData.forEach((row, index) => {
        if (!row.litigationTypeDetailName) {
          litigationTypesDetailNameErr.nameEmpty = "Litigation type detail name is required";
          setTimeout(() => {
            toast.error(litigationTypesDetailNameErr.nameEmpty, {
              theme: 'colored'
            });
          }, 1000);
          isValid = false;
          setFormError(true);
        }
      });
    }

    if (!isValid) {
      var errorObject = {
        litigationNameErr,
      };
      var errorLitigationTypeDetailObject = {
        litigationTypesDetailNameErr
      };
      dispatch(litigationDetailsErrorAction(errorObject));
      dispatch(litigationTypeDetailsErrorAction(errorLitigationTypeDetailObject))
    }

    return isValid;
  };

  const addLitigationTypeMasterDetails = async () => {
    
    if (litigationTypeMasterValidation()) {
      const requestData = {
        litigationName: litigationTypeMasterData.litigationName,
        AddLitigationTypesDetail: litigationTypesDetailData,
        AddUser: localStorage.getItem('LoginUserName')
      };

      const keys = [
        'litigationName',
        'AddUser'
      ];
      for (const key of Object.keys(requestData).filter(key => keys.includes(key))) {
        requestData[key] = requestData[key] ? requestData[key].toUpperCase() : '';
      }
      const litigationTypesDetailKey = ['litigationTypeDetailName', 'addUser']
      var index = 0;
      for (var obj in requestData.AddLitigationTypesDetail) {
        var stateDetailObj = requestData.AddLitigationTypesDetail[obj];

        for (const key of Object.keys(stateDetailObj).filter((key) => litigationTypesDetailKey.includes(key))) {
          stateDetailObj[key] = stateDetailObj[key] ? stateDetailObj[key].toUpperCase() : '';
        }
        requestData.AddLitigationTypesDetail[index] = stateDetailObj;
        index++;
      }
      setIsLoading(true);
      await dispatch(
        addLitigationTypeMasterAPI(
          requestData,
          response => {
            setIsLoading(false);
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });

            dispatch(
              litigationDetailAction({
                ...litigationTypeMasterData,
                encryptedLitigationCode: response.data.data.encryptedLitigationCode,
                litigationName: response.data.data.litigationName,
                litigationCode: response.data.data.litigationCode,
              })
            );
            updateCallback(true, response.data.data.encryptedLitigationCode);
            setModalShow(false);
            localStorage.setItem(
              'EncryptedResponseLitigationTypeMasterCode',
              response.data.data.encryptedLitigationCode
            );
          },
          err => {
            setIsLoading(false)
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          },
          headers
        )
      );
    }
  };

  const updateCallback = (isAddState = false,) => {
    setModalShow(false);
    dispatch(formChangedAction(undefined));
    if (!isAddState) {
      toast.success('Litigation detail updated successfully!', {
        theme: 'colored'
      });
    }

    $('#btnSave').attr('disabled', true);
    getLitigationList(1, perPage);
    getLitigationTypeDetailList(responseLitigationTypeMasterCode)

    $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={
                // !litigationTypeMasterData.encryptedCountryCode
                // ? 
                addLitigationTypeMasterDetails
                // : updateCountryDetails
              }
            >
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}
            >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <TabPage1
        listData={litigationTypeMasterList}
        listColumnArray={listColumnArray}
        tabArray={tabArray}
        module="LitigationTypeMaster"
        saveDetails={
          // litigationTypeMasterData.encryptedCountryCode
          //   ? updateCountryDetails
          //   :
          addLitigationTypeMasterDetails
        }
        newDetails={newDetails}
        cancelClick={cancelClick}
        exitModule={exitModule}
        pageSize={pageSize}
        setPageSize={(value) => setPageSize(value)}
        searchText={searchText}
        setSearchTextValue={(value) => setSearchText(value)}
        fetchList={getLitigationList}
        listCount={totalCount}
      />
    </>
  );
};

export default LitigationTypeMaster;
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import 'bootstrap/dist/css/bootstrap.min.css';
import { downloadCaseDatePdfAPI } from 'actions/Master/masterAction';
import tippy from 'tippy.js'; // Import tippy
import 'tippy.js/dist/tippy.css'; // Import tippy CSS for styling
import CaseDatePDFConfirmationModal from '../Modal/CaseDatePDFConfirmationModal';
import { useDispatch } from 'react-redux';
import "assets/css/custom.css"

const CalendarManagement = ({ data, fetchData }) => {
  const dispatch = useDispatch();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showDatePopup, setShowDatePopup] = useState(false);
  const [selectedCaseDate, setSelectedCaseDate] = useState(null);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const fetchDataForVisibleMonths = (date) => {
    const monthsArray = [0, 1, 2].map((offset) => {
      const newDate = new Date(date);
      newDate.setMonth(date.getMonth() + offset);
      return { month: newDate.getMonth() + 1, year: newDate.getFullYear() };
    });

    fetchData(monthsArray);
  };

  useEffect(() => {
    fetchDataForVisibleMonths(currentDate);
  }, [currentDate]);

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateClick = (info, eventsWithCaseCount) => {
    const hasEvent = eventsWithCaseCount.some(
      (event) => new Date(event.start).toDateString() === info.date.toDateString()
    );
    if (hasEvent) {
      setSelectedCaseDate(info.dateStr);
      setShowDatePopup(true);
    } else {
      setShowDatePopup(false);
    }
  };

  const handleConfirmDownload = async () => {
    setShowDatePopup(false);
    let requestData = {
      EncryptedAdvocateCode: localStorage.getItem("EncryptedAdvocateCode"),
      CaseDate: selectedCaseDate
    }
    dispatch(downloadCaseDatePdfAPI(requestData, headers, (res) => {
      if (res.headers['content-type'] != 'application/pdf') {
        //
      }
    }));
  };

  const handleNext = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + 1);
    setCurrentDate(newDate);
    fetchDataForVisibleMonths(newDate);
  };

  const handlePrev = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() - 1);
    setCurrentDate(newDate);
    fetchDataForVisibleMonths(newDate);
  };

  const renderCalendar = (offset) => {
    const displayDate = new Date(currentDate);
    displayDate.setMonth(currentDate.getMonth() + offset);
    const monthYear = displayDate.toLocaleString('default', {
      month: 'short',
      year: 'numeric',
    });

    const eventsWithCaseCount = data
      .filter((item) => {
        const itemDate = new Date(item.caseDate);
        return (
          itemDate.getMonth() === displayDate.getMonth() &&
          itemDate.getFullYear() === displayDate.getFullYear()
        );
      })
      .map((item) => ({
        start: item.caseDate,
        title: `Cases: ${item.caseCount}`,
        extendedProps: {
          caseCount: item.caseCount,
        },
      }));

    const addTooltip = (info) => {
      if (info.el) {
        const matchingItem = data.find((item) => formatDate(item.caseDate) === formatDate(info.date));
        if (matchingItem) {
          const caseCount = matchingItem.caseCount;

          if (!info.el._tooltipAdded) {
            const tooltip = tippy(info.el, {
              content: `Cases: ${caseCount}`,
              placement: 'top',
              trigger: 'mouseenter',
              delay: [0, 200],
            });

            tooltip.popper.addEventListener('mouseenter', () => {
              const tooltipId = tooltip.popper.id;
              info.el.setAttribute('aria-describedby', tooltipId);
            });
            info.el._tooltipAdded = true;
          }
        }
      }
    }

    useEffect(() => {
      if (eventsWithCaseCount.length > 0) {
        const dateCells = document.querySelectorAll('.fc-daygrid-day');
        dateCells.forEach((cell) => {
          if (!cell._tooltipAdded) {
            const info = { el: cell, date: cell.dataset.date };
            addTooltip(info);
          }
        });
      }
    }, [eventsWithCaseCount]);

    return (
      <Col className="p-0 m-0    calneder-1" md={4} key={offset}>
        <Card className="text-center calendar-raw calendar-row border-0 calenderCard  card-calendar">
          <Card.Header className="bg-light text-dark">{monthYear}</Card.Header>
          <Card.Body className='p-2 '>
            <FullCalendar
              key={displayDate.toISOString()}
              plugins={[dayGridPlugin, interactionPlugin]}
              headerToolbar={false}
              initialView="dayGridMonth"
              firstDay={1}
              dayHeaderFormat={{ weekday: 'narrow' }}
              initialDate={displayDate.toISOString().slice(0, 10)}
              height="auto"
              scrollTimeReset={false}
              contentHeight='auto'
              dayCellClassNames={(date) => {
                const hasEvent = eventsWithCaseCount.some(
                  (event) => new Date(event.start).toDateString() === date.date.toDateString()
                );
                return hasEvent ? 'has-event' : '';
              }}
              events={eventsWithCaseCount}
              dateClick={(info) => handleDateClick(info, eventsWithCaseCount)}
              dayCellDidMount={(info) => {
                addTooltip(info);
              }}
              datesSet={() => {
                const dateCells = document.querySelectorAll('.fc-daygrid-day');
                dateCells.forEach((cell) => {
                  if (!cell._tooltipAdded) {
                    const info = { el: cell, date: cell.dataset.date };
                    addTooltip(info);
                  }
                });
              }}
              dayCellContent={(arg) => {
                return (
                  <div className='dates' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '0' }}>
                    <span style={{ fontWeight: 'bold' }}>{arg.dayNumberText}</span>
                  </div>
                );
              }}
              // showNonCurrentDates={false}
              fixedWeekCount={false}
            />
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <div>
        <Card className="card-dashbord-bg mt-2 mb-2 card-bottom-padding border-0">
          <Row className="align-items-center m-0 p-0">
            <Col md={9} sm={9} xl={9} className="p-0">
              <Card.Header className=" fw-bolder text-center ms-5 case-schedule-card-dash text-white">
                Case Calendar
              </Card.Header>
            </Col>
            <Col sm={3} md={3} xl={3} className="text-end">
              <Button onClick={handlePrev} type="button" className="me-1 btn btn-falcon-default btn-sm"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" className="button-arrow svg-inline--fa fa-chevron-left fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg></Button>
              <Button onClick={handleNext} type="button" className="ms-1 btn btn-falcon-default btn-sm"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="button-arrow svg-inline--fa fa-chevron-right fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg></Button>
            </Col>
          </Row>
        </Card>
        <Row className=" m-0 p-0 ">
          {[0, 1, 2].map((offset) => renderCalendar(offset))}
        </Row>
      </div>
      <CaseDatePDFConfirmationModal
        show={showDatePopup}
        onConfirm={handleConfirmDownload}
        onCancel={() => setShowDatePopup(false)}
        data={selectedCaseDate}
      />
    </>
  );
};

export default CalendarManagement;

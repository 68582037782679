const initialState = {
    caseTypeMasterDetailsError: {
      caseTypeErr: {},
      countryNameErr: {},
      stateNameErr: {},
      courtNameErr: {},
    }
  };
  const caseTypeMasterErrorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CASETYPEMASTERDETAILSERROR':
        if (!action.payload) {
          return initialState;
        } else {
          return {
            ...state,
            caseTypeMasterDetailsError: action.payload
          };
        }
      default:
        return state;
    }
  };
  
  export default caseTypeMasterErrorReducer;
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Tabs, Tab, Button, Modal, Col, Row } from 'react-bootstrap';
import TabPageMainMenu from 'components/navbar/top/TabPageMainMenu';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import AdvocateDetail from 'components/Advocate/AdvocateDetail';
import UserDetail from 'components/User/UserDetail';
import TransactionDetailList from 'components/Advocate/TransactionDetailList';
import TransactionDetails from 'components/Advocate/TransactionDetails';
import ClientDetail from 'components/Clients/ClientDetails';
import AdvocateClientCaseDetail from 'components/AdvocateClientCase/AdvocateClientCaseDetail';
import CaseNextDateDetail from 'components/CaseNextDate/CaseNextDateDetail';
import FilePlacementDetail from 'components/FilePlacement/FilePlacementDetail';
import AdvocateDealing from 'components/AdvocateDealing/AdvocateDealing';
import SecurityRoleMasterDetail from 'components/SecurityRoleMaster/SecurityRoleMasterDetail';
import CountryMasterDetail from 'components/CountryMaster/CountryMasterDetail';
import StateMaster from 'components/CountryMaster/StateMaster';
import CourtTypeMasterDetail from 'components/CourtTypeMaster/CourtTypeMasterDetail';
import AddSecurityMenuTreeDetails from 'components/SecurityMenuTree/AddSecurityMenuTreeDetails';
import CourtMasterDetail from 'components/CourtMaster/CourtMasterDetail';
import { CaseScheduleDetail } from 'components/CaseSchedule/CaseScheduleDetail';
import ProfileDetail from 'components/Profile/ProfileDetail';
import AdvanceTableComponent1 from './advance-table-1/AdvanceTableComponent1';
import LitigationTypeMasterDetail from 'components/LitigationTypeMaster/LitigationTypeMasterDetail';
import LitigationTypeMasterDetailsData from 'components/LitigationTypeMaster/LitigationTypeMasterDetailsData';
import CaseTypeMasterDetail from 'components/CaseTypeMaster/CaseTypeMasterDetail';
//Datatable Modules

const TabPage1 = ({
  listData,
  listColumnArray,
  tabArray,
  module,
  saveDetails,
  newDetails,
  cancelClick,
  exitModule,
  tableFilterOptions,
  tableFilterName,
  filterValue,
  supportingMethod1,
  tableFilterOptions1,
  tableFilterName1,
  filterValue1,
  supportingMethod2,
  supportingButtonClick,
  yearList,
  handleYearChange,
  yearFilterName,
  selectedYear,
  pageSize,
  setPageSize,
  fetchList,
  searchText,
  setSearchTextValue,
  listCount
}) => {
  $.fn.extend({
    trackChanges: function () {
      $(':input', this).change(function () {
        $(this.form).data('changed', true);
        if ($('#btnSave').attr('disabled'))
          $('#btnSave').attr('disabled', false);
      });
    },
    isChanged: function () {
      return this.data('changed');
    }
  });

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  const isAnyFormDirty = Object.values(formChangedData).some(
    value => value === true
  );

  if (isAnyFormDirty) {
    document.getElementById('btnSave').disabled = false;
  }

  useEffect(() => {
    $('[data-rr-ui-event-key*="Advocate List"]').trigger('click');
    $('[data-rr-ui-event-key*="Details"]').attr('disabled', true);
    $('#btnNew').show();
    $('#btnSave').hide();
    $('#btnSave').attr('disabled', true);
    $('#btnCancel').hide();
    $('.tab-page-list-card').removeClass('card');
    $('.add-farmer-card').removeClass('card');
    $('.add-farmer-card-body').removeClass('bg-light');
    localStorage.removeItem('EncryptedResponseAdvocateCode');
    localStorage.removeItem('EncryptedClientCode');
    localStorage.removeItem('EncryptedResponseCaseId');
    localStorage.removeItem('ClientCode');
    localStorage.removeItem('CaseNo');
    localStorage.removeItem('ClientName');
  }, []);

  const discardChanges = () => {
    if ($('#btnExit').attr('isExit') == 'true')
      window.location.href = '/dashboard';
    else $('[data-rr-ui-event-key*="List"]').trigger('click');

    setModalShow(false);
  };

  const [modalShow, setModalShow] = React.useState(false);

  const save = () => {
    $('#btnSave').trigger('click');
    setModalShow(false);
  };

  // console.log('tab page list data', listData);

  return (
    <>
      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={save}>
              Save
            </Button>
            <Button variant="danger" onClick={discardChanges}>
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <TabPageMainMenu
        newClick={newDetails}
        saveClick={saveDetails}
        cancelClick={cancelClick}
        exitClick={exitModule}
        module={module}
        purchaseReportClick={supportingButtonClick}
      />

      <Tabs id="uncontrolled-tab-example" className="mb-2 mt-2">
        {Object.values(tabArray).map((tab, index) => {
          return (
            <Tab
              key={index}
              eventKey={tab}
              title={tab}
              className={
                index == 0
                  ? 'border p-1'
                  : tab == 'Advocate Details' ||
                    tab == 'User Details' ||
                    tab == 'Client Details' ||
                    tab == 'Case Details' ||
                    tab == 'Add Country' ||
                    tab == 'Add Court Type' ||
                    tab == 'Add Case Type Master'
                  ? 'border p-1 tab-page-tab'
                  : ''
              }
            >
              {index == 0 && listData && (
                <>
                  <FalconComponentCard className="tab-page-list-card">
                    <FalconComponentCard.Body>
                      <AdvanceTableComponent1
                        columns={listColumnArray}
                        module={module}
                        data={listData}
                        filterOptions={tableFilterOptions}
                        filterName={tableFilterName}
                        filterValue={filterValue}
                        handleFilterChange={supportingMethod1}
                        filterOptions1={tableFilterOptions1}
                        filterName1={tableFilterName1}
                        filterValue1={filterValue1}
                        handleFilterChange1={supportingMethod2}
                        yearList={yearList}
                        yearFilterName={yearFilterName}
                        handleYearChange={handleYearChange}
                        selectedYear={selectedYear}
                        fetchList={fetchList}
                        dataCount={listCount}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        searchText={searchText}
                        setSearchTextValue={setSearchTextValue}
                      />
                    </FalconComponentCard.Body>
                  </FalconComponentCard>
                </>
              )}

              {index == 1 && module == 'Advocate' && (
                <>
                  <FalconComponentCard className="mb-2 no-pb">
                    <FalconComponentCard.Body language="jsx">
                      <AdvocateDetail />
                    </FalconComponentCard.Body>
                  </FalconComponentCard>
                </>
              )}
              {index == 2 && module == 'Advocate' && (
                <>
                  <FalconComponentCard
                    id="TransactionDetailsListCard"
                    className="tab-page-table-card mb-2 no-pad"
                  >
                    <FalconComponentCard.Body language="jsx">
                      <TransactionDetailList />
                    </FalconComponentCard.Body>
                  </FalconComponentCard>

                  <FalconComponentCard
                    id="AddTransactionDetailsForm"
                    className="mb-0 no-pb"
                  >
                    <FalconComponentCard.Body language="jsx">
                      <TransactionDetails />
                    </FalconComponentCard.Body>
                  </FalconComponentCard>
                </>
              )}
              {index == 3 && module == 'Advocate' && (
                <>
                  <AdvocateDealing />
                </>
              )}

              {index == 1 && module == 'User' && (
                <>
                  <UserDetail />
                  {/* <FalconComponentCard className="mb-2 no-pb">
                    <FalconComponentCard.Body language="jsx">
                      
                    </FalconComponentCard.Body>
                  </FalconComponentCard> */}
                </>
              )}
              {index == 1 && module == 'Client' && (
                <>
                  <FalconComponentCard className="mb-2 no-pb">
                    <FalconComponentCard.Body language="jsx">
                      <ClientDetail />
                    </FalconComponentCard.Body>
                  </FalconComponentCard>
                </>
              )}

              {index == 1 && module == 'CaseRegistration' && (
                <>
                  <AdvocateClientCaseDetail />
                </>
              )}

              {index == 0 && module == 'CaseNextDate' && (
                <>
                  <CaseNextDateDetail />
                </>
              )}

              {index == 1 && module == 'FilePlacement' && (
                <>
                  <FilePlacementDetail />
                </>
              )}
              {index == 1 && module == 'SecurityRoleMaster' && (
                <>
                  <FalconComponentCard className="mb-2 no-pb">
                    <FalconComponentCard.Body language="jsx">
                      <SecurityRoleMasterDetail />
                    </FalconComponentCard.Body>
                  </FalconComponentCard>
                </>
              )}
              {index == 1 && module == 'CountryMaster' && (
                <>
                  <CountryMasterDetail />
                  <StateMaster />
                </>
              )}
              {index == 1 && module == 'CourtTypeMaster' && (
                <>
                  <CourtTypeMasterDetail />
                </>
              )}
              {index == 0 && module == 'SecurityMenuTree' && (
                <>
                  <FalconComponentCard>
                    <FalconComponentCard.Body
                      language="jsx"
                      className="full-tab-page-card-body"
                    >
                      <AddSecurityMenuTreeDetails />
                    </FalconComponentCard.Body>
                  </FalconComponentCard>
                </>
              )}
              {index == 1 && module == 'CourtMaster' && (
                <>
                  <CourtMasterDetail />
                </>
              )}
              {index == 0 && module == 'CaseSchedule' && (
                <>
                  <FalconComponentCard className="no-pb">
                    <FalconComponentCard.Body
                      language="jsx"
                      className="full-tab-page-card-body pt-2"
                    >
                      <CaseScheduleDetail />
                    </FalconComponentCard.Body>
                  </FalconComponentCard>
                </>
              )}
              {index == 0 && module == 'Profile' && (
                <>
                  <FalconComponentCard>
                    <FalconComponentCard.Body
                      language="jsx"
                      className="full-tab-page-card-body"
                    >
                      <ProfileDetail />
                    </FalconComponentCard.Body>
                  </FalconComponentCard>
                </>
              )}

{index == 1 && module == 'LitigationTypeMaster' && (
                <>
                  <LitigationTypeMasterDetail />
                  <LitigationTypeMasterDetailsData/>
                </>
              )}

              {index == 1 && module == 'CaseTypeMaster' && (
                <>
                  <CaseTypeMasterDetail />
                </>
              )}
            </Tab>
          );
        })}
      </Tabs>
    </>
  );
};

export default TabPage1;

import React, { useContext, useEffect } from 'react';
import {  Outlet, useLocation } from 'react-router-dom';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import Footer from 'components/footer/Footer';
import classNames from 'classnames';
import { isLoggedIn } from 'helpers/utils';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    isLoggedIn();
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  const requireAuth = (element) => {
    var isAuth = isLoggedIn();

    if (isAuth)
      return element;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if ($('.tab-page-nav-bar').length == 0) {
      $('.default-navbar').show();
    }
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <div className={classNames('content ', { 'pb-0': isKanban })}>
        <div className='text-end me-5 align-bottom theme-color-div '><h4 className='theme-color'>{localStorage.getItem('LoginUserName')}</h4></div>
        {/*------ Main Routes ------*/}
        {requireAuth(<Outlet />)}
        {!isKanban && <Footer />}
      </div>
    </div>
  );
};

export default MainLayout;

import {
  GET_LITIGATION_TYPES_DETAIL_LIST_FAILURE,
  GET_LITIGATION_TYPES_DETAIL_LIST_REQUEST,
  GET_LITIGATION_TYPES_DETAIL_LIST_SUCCESS

} from "actions/LitigationTypesDetail/litigationTypesDetailAction";
const initialState = {
  litigationTypeList: [],
  litigationTypeDetail: [],
  litigationTypeDetailData: [],

};

export default function litigationTypesDetailReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_LITIGATION_TYPES_DETAIL_LIST_REQUEST:
      return {
        ...state
      };
    case GET_LITIGATION_TYPES_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        litigationTypeList: action.payload
      };
    case GET_LITIGATION_TYPES_DETAIL_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      };
 
      case 'LITIGATIONTYPEDETAIL':
        if (!action.payload) {
            return initialState;
        } else {
            return {
                ...state,
                litigationTypeDetail: action.payload
            };
        }
    case 'LITIGATIONTYPEDETAILDATA':
        if (!action.payload) {
            return initialState;
        } else {
            return {
                ...state,
                litigationTypeDetailData: action.payload
            };
        }
    default:
      return state;
  }
}
import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const COURTMASTER_LIST_REQUEST = 'COURTMASTER_LIST_REQUEST';
export const COURTMASTER_LIST_SUCCESS = 'COURTMASTER_LIST_SUCCESS';
export const COURTMASTER_LIST_FAILURE = 'COURTMASTER_LIST_FAILURE';

export const GET_COURTMASTER_LIST_REQUEST = 'GET_COURTMASTER_LIST_REQUEST';
export const GET_COURTMASTER_LIST_SUCCESS = 'GET_COURTMASTER_LIST_SUCCESS';
export const GET_COURTMASTER_LIST_FAILURE = 'GET_COURTMASTER_LIST_FAILURE';

export const ADD_COURT_MASTER_REQUEST = 'ADD_COURT_MASTER_REQUEST';
export const ADD_COURT_MASTER_SUCCESS = 'ADD_COURT_MASTER_SUCCESS';
export const ADD_COURT_MASTER_FAILURE = 'ADD_COURT_MASTER_FAILURE';

export const UPDATE_COURT_MASTER_REQUEST = 'UPDATE_COURT_MASTER_REQUEST';
export const UPDATE_COURT_MASTER_SUCCESS = 'UPDATE_COURT_MASTER_SUCCESS';
export const UPDATE_COURT_MASTER_FAILURE = 'UPDATE_COURT_MASTER_FAILURE';

export const courtMasterListRequest = () => ({
  type: COURTMASTER_LIST_REQUEST
});
export const courtMasterListSuccess = payload => ({
  type: COURTMASTER_LIST_SUCCESS,
  payload
});
export const courtMasterListFailure = payload => ({
  type: COURTMASTER_LIST_FAILURE,
  payload
});

export const getCourtMasterListRequest = () => ({
  type: GET_COURTMASTER_LIST_REQUEST
});
export const getCourtMasterListSuccess = payload => ({
  type: GET_COURTMASTER_LIST_SUCCESS,
  payload
});
export const getCourtMasterListFailure = payload => ({
  type: GET_COURTMASTER_LIST_FAILURE,
  payload
});

export const AddCourtMasterRequest = () => ({
  type: ADD_COURT_MASTER_REQUEST
});
export const AddCourtMasterSuccess = payload => ({
  type: ADD_COURT_MASTER_SUCCESS,
  payload
});
export const AddCourtMasterFailure = payload => ({
  type: ADD_COURT_MASTER_FAILURE,
  payload
});

export const updateCourtMasterRequest = () => ({
  type: UPDATE_COURT_MASTER_REQUEST
});
export const updateCourtMasterSuccess = payload => ({
  type: UPDATE_COURT_MASTER_SUCCESS,
  payload
});
export const updateCourtMasterFailure = payload => ({
  type: UPDATE_COURT_MASTER_FAILURE,
  payload
});

export const courtMasterDataDetailAction = payload => {
  return {
    type: 'COURTMASTERDETAIL',
    payload
  };
};

export const courtMasterDetailErrorAction = payload => {
  return {
    type: 'COURTMASTERDETAILSERROR',
    payload
  };
};

export const courtMasterListAPI = (
  payload,
  successData,
  errorMSg,
  headers = {}
) => {
  return dispatch => {
    dispatch(courtMasterListRequest());
    const url = `${REACT_APP_API_URL}/get-court-master-list`;
    return request('POST', url, payload, '', headers)
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(courtMasterListSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(courtMasterListFailure('Something went wrong'));
        throw err;
      });
  };
};

export const getCourtMasterListAPI = (
  payload,
  successData,
  errorMSg,
  headers = {}
) => {
  return dispatch => {
    dispatch(getCourtMasterListRequest());
    const url = `${REACT_APP_API_URL}/court-master-list`;
    return request('POST', url, payload, '', headers)
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(getCourtMasterListSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(getCourtMasterListFailure('Something went wrong'));
        throw err;
      });
  };
};

export const addCourtMasterAPI = (
  payload,
  successData,
  errorMSg,
  headers = {}
) => {
  return dispatch => {
    dispatch(AddCourtMasterRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/add-court-master'}`,
      payload,
      '',
      headers
    )
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(AddCourtMasterSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(AddCourtMasterFailure('Something went wrong'));
        throw err;
      });
  };
};

export const updateCourtMasterAPI = (
  payload,
  successData,
  errorMSg,
  headers = {}
) => {
  return dispatch => {
    dispatch(updateCourtMasterRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/update-court-master'}`,
      payload,
      '',
      headers
    )
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(updateCourtMasterSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(updateCourtMasterFailure('Something went wrong'));
        throw err;
      });
  };
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Table } from 'react-bootstrap';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import ClientCaseDetailModal from '../Modal/ClientCaseDetailModal';

const AdvanceTable2 = ({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps
}) => {
  const table = useAdvanceTableContext();
  const { getRowModel, getFlatHeaders } = table;

  const [showModal, setShowModal] = useState(false);
  const [selectedClientCode, setSelectedClientCode] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");

  const handleCaseClick = cellData => {
    if(cellData.totalNoOfCase != 0){
    setSelectedClientCode(cellData.clientCode);
    setSelectedClientName(cellData.clientName);
    setShowModal(true);
    }else{
      setShowModal(false);
    }
  };

  return (
    <>
      <div className="table-responsive scrollbar">
        <Table {...tableProps}>
          <thead className={headerClassName}>
            <tr>
              {getFlatHeaders().map(header => {
                return (
                  <th
                    key={header.id}
                    {...header.column.columnDef.meta?.headerProps}
                    className={classNames(
                      'fs-10',
                      header.column.columnDef.meta?.headerProps?.className,
                      // {
                      //   sort: header.column.getCanSort(),
                      //   desc: header.column.getIsSorted() === 'desc',
                      //   asc: header.column.getIsSorted() === 'asc'
                      // }
                    )}
                    // onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={bodyClassName}>
            {getRowModel().rows.map(row => (
              <tr key={row.id} className={rowClassName}>
                {/* {row.getVisibleCells().map(cell => (
                <td key={cell.id} {...cell.column.columnDef.meta?.cellProps}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))} */}
                {row.getVisibleCells().map((cell, index) => {
                  return (
                    <>
                      <td
                        key={index}
                        {...cell.column.columnDef.meta?.cellProps}
                      >
                        {cell.column.id !== 'totalNoOfCase' ? (
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        ) : (
                          <Badge
                            pill
                            bg="success"
                            onClick={() => handleCaseClick(cell.row.original)}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Badge>
                        )}
                      </td>
                    </>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
        <ClientCaseDetailModal
          show={showModal}
          hide={() => setShowModal(false)}
          selectedClientCode={selectedClientCode}
          selectedClientName={selectedClientName}
        />
      </div>
    </>
  );
};
AdvanceTable2.propTypes = {
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object
};

export default AdvanceTable2;
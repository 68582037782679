import request from "components/Utils/Request";

export const ADD_COUNTRY_MASTER_REQUEST = "ADD_COUNTRY_MASTER_REQUEST";
export const ADD_COUNTRY_MASTER_SUCCESS = "ADD_COUNTRY_MASTER_SUCCESS";
export const ADD_COUNTRY_MASTER_FAILURE = "ADD_COUNTRY_MASTER_FAILURE";
export const UPDATE_COUNTRY_MASTER_REQUEST = "UPDATE_COUNTRY_MASTER_REQUEST";
export const UPDATE_COUNTRY_MASTER_SUCCESS = "UPDATE_COUNTRY_MASTER_SUCCESS";
export const UPDATE_COUNTRY_MASTER_FAILURE = "UPDATE_COUNTRY_MASTER_FAILURE";
export const COUNTRY_MASTER_LIST_REQUEST = "COUNTRY_MASTER_LIST_REQUEST";
export const COUNTRY_MASTER_LIST_SUCCESS = "COUNTRY_MASTER_LIST_SUCCESS";
export const COUNTRY_MASTER_LIST_FAILURE = "COUNTRY_MASTER_LIST_FAILURE";
export const DELETE_COUNTRY_MASTER_REQUEST = "DELETE_COUNTRY_MASTER_REQUEST";
export const DELETE_COUNTRY_MASTER_SUCCESS = "DELETE_COUNTRY_MASTER_SUCCESS";
export const DELETE_COUNTRY_MASTER_FAILURE = "DELETE_COUNTRY_MASTER_FAILURE";

const { REACT_APP_API_URL } = process.env;

export const addCountryMasterRequest = () => ({
  type: ADD_COUNTRY_MASTER_REQUEST,
});

export const addCountryMasterSuccess = (payload) => ({
  type: ADD_COUNTRY_MASTER_SUCCESS,
  payload,
});

export const addCountryMasterFailure = (payload) => ({
  type: ADD_COUNTRY_MASTER_FAILURE,
  payload,
});

export const updateCountryMasterRequest = () => ({
  type: UPDATE_COUNTRY_MASTER_REQUEST,
});

export const updateCountryMasterSuccess = (payload) => ({
  type: UPDATE_COUNTRY_MASTER_SUCCESS,
  payload,
});

export const updateCountryMasterFailure = (payload) => ({
  type: UPDATE_COUNTRY_MASTER_FAILURE,
  payload,
});

export const countryMasterListRequest = () => ({
  type: COUNTRY_MASTER_LIST_REQUEST,
});

export const countryMasterListSuccess = (payload) => ({
  type: COUNTRY_MASTER_LIST_SUCCESS,
  payload,
});

export const countryMasterListFailure = (payload) => ({
  type: COUNTRY_MASTER_LIST_FAILURE,
  payload,
});

export const deleteCountryMasterRequest = () => ({
  type: DELETE_COUNTRY_MASTER_REQUEST
});
export const deleteCountryMasterSuccess = (payload) => ({
  type: DELETE_COUNTRY_MASTER_SUCCESS,
  payload
});
export const deleteCountryMasterFailure = (payload) => ({
  type: DELETE_COUNTRY_MASTER_FAILURE,
  payload
})

export const countryDetailAction = payload => {
  return {
    type: 'COUNTRYDETAIL',
    payload
  };
};

export const countryDetailsErrorAction = payload => {
  return {
    type: 'COUNTRYDETALERROR',
    payload
  };
};

export const countryDeletedSuccessAction = payload => {
  return {
    type: 'DELETE_COUNTRY_SUCCESS',
    payload
  };
};

export const resetCountryDeletedAction = payload => {
  return {
    type: 'RESET_COUNTRY_DELETED',
    payload
  };
};

export const countryListAPI = (payload, successData, errorMSg,headers={}) => {
  return (dispatch) => {
    dispatch(countryMasterListRequest());
    return request("POST", `${REACT_APP_API_URL + "/country-list"}`, payload, "", headers)
      .then((res) => {
        if (res?.data?.status === 200) {
          successData(res);
          dispatch(countryMasterListSuccess(res.data.data));
        } else {
          errorMSg(res);
          dispatch(countryMasterListFailure(res));
        }
      })
      .catch((error) => {
        errorMSg(error?.response);
        dispatch(countryMasterListFailure("Something went wrong"));
        throw error;
      });
  };
};

export const addCountryAPI = (payload, successData, errorMsg,header={}) => {
  return (dispatch) => {
      dispatch(addCountryMasterRequest());
      return request("POST", `${REACT_APP_API_URL + "/add-country-master"}`, payload,"",header)
          .then((response) => {
              if (response?.data?.status === 200) {
                  successData(response);
                  dispatch(addCountryMasterSuccess(response));
              } else {
                  errorMsg(response);
              }

          }).catch((error) => {
              errorMsg(error?.response);
              dispatch(addCountryMasterFailure("Something Went Wrong"));
              throw error;
          });
  };
};

export const updateCountryAPI = (payload, successData, errorMsg,header={}) => {
  return (dispatch) => {
      dispatch(updateCountryMasterRequest());
      return request("POST", `${REACT_APP_API_URL + "/update-country-master"}`, payload,"",header)
          .then((response) => {
              if (response?.data?.status === 200) {
                  successData(response);
                  dispatch(updateCountryMasterSuccess(response));
              } else {
                  errorMsg(response);
              }

          }).catch((error) => {
              errorMsg(error?.response);
              dispatch(updateCountryMasterFailure("Something Went Wrong"));
              throw error;
          });
  };
};

export const deleteCountryMasterDetailAPI = (payload, successData, errorMsg, headers = {}) => {
  return (dispatch) => {
      const url = `${REACT_APP_API_URL}/delete-country-master-detail`;
      dispatch(deleteCountryMasterRequest());
      return request("DELETE", url, payload, "", headers)
          .then((res) => {
              if (res?.data.status === 200) {
                  successData(res);
                  dispatch(deleteCountryMasterSuccess(res));
              } else {
                  errorMsg(res);
              }
          }).catch((error => {
              errorMsg(error?.res);
              dispatch(deleteCountryMasterFailure("Something went wrong"));
              throw error;
          }
      ));
  }
}
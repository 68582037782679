import { clientCaseDetailListAPI } from 'actions/AdvocateClientCase/AdvocateClientCaseAction';
import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const ClientCaseDetailModal = ({ show, hide, selectedClientCode, selectedClientName }) => {
  const dispatch = useDispatch();
  const [clientCaseDetailList, setClientCaseDetailList] = useState([]);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  useEffect(() => {
    if (show && selectedClientCode) {
      getClientCaseDetailList(selectedClientCode);
    }
  }, [show, selectedClientCode]);

  const getClientCaseDetailList = () => {
    const data = {
      encryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
      clientCode: selectedClientCode
    };
    dispatch(
      clientCaseDetailListAPI(
        data,
        response => {
          setClientCaseDetailList(response.data.data.clientCaseDetailList);
        },
        err => {
          setClientCaseDetailList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={hide}
        centered
        // size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Case Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modelClientList">
          <h4 className="text-center client-list-popup-card-heading">
            Client Name -{' '}
            <span className="table-title">{selectedClientName || ''}</span>
          </h4>
          <Table
            striped
            bordered
            responsive
            id="TableList"
            className="no-pb text-nowrap tab-page-table custom-table"
          >
            <thead className="custom-bg-200">
              <tr>
                <th>S.No.</th>
                <th>Case No.</th>
                <th>Previous Date</th>
                <th>Current Date</th>
                <th>File Location(F-R-A-R)</th>
              </tr>
            </thead>
            <tbody>
              {clientCaseDetailList.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.caseNo}</td>
                  <td>{item.previousDate}</td>
                  <td>{item.nextDate}</td>
                  <td>{item.fileLocation}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClientCaseDetailModal;
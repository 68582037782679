import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { formChangedAction } from 'actions';
import { addCaseNextDateDetailAPI, caseNextDateDataAction, caseNextDateDetailsErrorAction } from 'actions/CaseNextDate/caseNextDateAction';
import { toast } from 'react-toastify';

const tabArray = ['Case Next Date'];

export const CaseNextDate = () => {
  const dispatch = useDispatch();

  const selectedClientCode = localStorage.getItem('CaseScheduleClientCode') ? localStorage.getItem('CaseScheduleClientCode') : localStorage.getItem('ClientCode');
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');

  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  useEffect(() => {
    $("#btnNew").hide();
    $('#btnSave').attr('disabled', false);
    $("#btnSave").show();
    localStorage.removeItem("CaseNo")
    localStorage.removeItem("ClientName")
    localStorage.removeItem('ClientCode')
  }, [])

  const caseNextDateReducer = useSelector(
    state => state.rootReducer.caseNextDateReducer
  );
  var caseNextDateData = caseNextDateReducer.caseNextDateData

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );

  useEffect(() => {
    if (caseNextDateData.currentCaseStatus === "DISPOSED") {
      $('#btnSave').attr('disabled', true);
      $("#btnSave").hide();
    } else {
      $('#btnSave').attr('disabled', false);
      $("#btnSave").show();
    }
  }, [caseNextDateData])

  const clearCaseNextDateReducers = () => {
    dispatch(caseNextDateDataAction(undefined))
    dispatch(caseNextDateDetailsErrorAction(undefined))
    dispatch(formChangedAction(undefined));
    localStorage.removeItem('CaseNo')
    localStorage.removeItem('ClientCode')
    localStorage.removeItem("ClientName")
    localStorage.removeItem('CaseScheduleClientCode')
    localStorage.removeItem('CaseScheduleCaseNo')
    localStorage.removeItem('CaseScheduleClientName')
    $('#txtClientCode').val('')
  };

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      window.location.href = '/dashboard';
      dispatch(caseNextDateDataAction(undefined))
      localStorage.removeItem("ClientCode")
      localStorage.removeItem("ClientName")
      localStorage.removeItem("CaseNo")
      localStorage.removeItem('CaseScheduleClientCode')
      localStorage.removeItem('CaseScheduleCaseNo')
      localStorage.removeItem('CaseScheduleClientName')
    }
  };

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true')
      window.location.href = '/dashboard';
    else $('[data-rr-ui-event-key*="List"]').trigger('click');
    setModalShow(false);
  };

  const caseNextDateValidation = () => {
    const clientNameErr = {};
    const caseNoErr = {};
    const newCourtNameErr = {};
    const newAdvocateMobileNoErr = {};
    const newNextHearingDateErr = {};

    let isValid = true;
    if (!selectedClientCode) {
      clientNameErr.nameEmpty = 'Select client';
      isValid = false;
    }

    if (!caseNextDateData.clientCaseNo) {
      caseNoErr.caseNoEmpty = 'Select case no';
      isValid = false;
    }

    if (!caseNextDateData.newCourtCode) {
      newCourtNameErr.courtEmpty = 'Select court';
      isValid = false;
    }

    if (!caseNextDateData.newNextHearingDate) {
      newNextHearingDateErr.nxtHearingDateEmpty = 'Select next hearing date';
      isValid = false;
    }
    else if (caseNextDateData.newNextHearingDate <= caseNextDateData.caseRegisterDate) {
      newNextHearingDateErr.endDateInvalid = "Next hearing date cannot be same or less than register Date";
      isValid = false;
    } 
    // else if (caseNextDateData.newNextHearingDate <= caseNextDateData.currentDate) {
    //   newNextHearingDateErr.endDateInvalid = "Next hearing date cannot be same or less than previous date";
    //   isValid = false;
    // }

    if (caseNextDateData.oppAdvMob) {
      if (!/^(?!0)[0-9]{10}$/.test(caseNextDateData.oppAdvMob)) {
        newAdvocateMobileNoErr.phoneInvalid =
          'Invalid mobile number, number should be of 10 digits should not start with 0';
        isValid = false;
      }
    }

    if (!isValid) {
      var errorObject = {
        clientNameErr,
        caseNoErr,
        newCourtNameErr,
        newAdvocateMobileNoErr,
        newNextHearingDateErr,
      };
      dispatch(caseNextDateDetailsErrorAction(errorObject));
    }

    return isValid;
  };

  const addCaseNextDateDetails = async () => {
    if (caseNextDateValidation()) {
      const data = {
        EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
        ClientCode: caseNextDateData.clientCode ? caseNextDateData.clientCode : selectedClientCode,
        AttendantAdvocateName: caseNextDateData.newAdvocate ? caseNextDateData.newAdvocate : caseNextDateData.attendantAdvocateName,
        CaseStatus: caseNextDateData.newCaseStatus ? caseNextDateData.newCaseStatus : caseNextDateData.currentCaseStatus,
        ClientCaseNo: caseNextDateData.clientCaseNo,
        CourtCode: caseNextDateData.currentCourtCode,
        NextCourtCode: caseNextDateData.newCourtCode ? caseNextDateData.newCourtCode : caseNextDateData.currentCourtCode,
        JudgeName: caseNextDateData.newJudge ? caseNextDateData.newJudge : caseNextDateData.currentJudge,
        CaseRegisterDate: caseNextDateData.caseRegisterDate,
        RoomNo: caseNextDateData.roomNo ? caseNextDateData.roomNo : "",
        Almirah: caseNextDateData.almirahNo ? caseNextDateData.almirahNo : "",
        Rack: caseNextDateData.rackNo ? caseNextDateData.rackNo : "",
        NextHearingDate: caseNextDateData.newNextHearingDate,
        CourtNo: caseNextDateData.newCourtNo ? caseNextDateData.newCourtNo : caseNextDateData.currentCourtNo,
        Description: caseNextDateData.advocateComment ? caseNextDateData.advocateComment : "",
        FileDescription: caseNextDateData.fileDescription ? caseNextDateData.fileDescription : "",
        ClientComment: caseNextDateData.clientComment ? caseNextDateData.clientComment : "",
        Purpose: caseNextDateData.newPurpose ? caseNextDateData.newPurpose : caseNextDateData.currentPurpose,
        PreviousDate: caseNextDateData.currentDate,
        FileNo: caseNextDateData.fileNo ? caseNextDateData.fileNo : "",
        PoliceStation: caseNextDateData.currentPoliceStation ? caseNextDateData.currentPoliceStation : "",
        FIRDate: caseNextDateData.currentFIRDate ? caseNextDateData.currentFIRDate : null,
        CaseNature: caseNextDateData.caseNature ? caseNextDateData.caseNature : "",
        CaseTitle: caseNextDateData.caseTitle,
        OppAdvName: caseNextDateData.oppAdvocateName ? caseNextDateData.oppAdvocateName : "",
        OppAdvMob: caseNextDateData.oppAdvMob ? caseNextDateData.oppAdvMob : "",
        UnderSection: caseNextDateData.underSection ? caseNextDateData.underSection : "",
        FIRNo: caseNextDateData.currentFIRNo ? caseNextDateData.currentFIRNo : "",
        EmailId: caseNextDateData.emailId ? caseNextDateData.emailId : "",
        AddUser: localStorage.getItem('LoginUserName')
      }

      const keys = [
        'AttendantAdvocateName',
        'CaseStatus',
        'ClientCaseNo',
        'FileDescription',
        'FIRNo',
        'ClientComment',
        'Description',
        'JudgeName',
        'PoliceStation',
        'CaseNature',
        'CaseTitle',
        'OppAdvName',
        'EmailId',
        'FileNo',
        'CourtNo',
        'RoomNo',
        'Rack',
        'Party',
        'UnderSection',
        'AddUser'
      ];
      for (const key of Object.keys(data).filter(key => keys.includes(key))) {
        data[key] = data[key] ? data[key].toUpperCase() : '';
      }
      setIsLoading(true);
      await dispatch(
        addCaseNextDateDetailAPI(
          data,
          response => {
            setIsLoading(false);
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });
            setModalShow(false);
            clearCaseNextDateReducers()
          },
          err => {
            setIsLoading(false)
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          },
          headers
        )
      );
      // clearCaseNextDateReducers()
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}
      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={
                addCaseNextDateDetails
              } >
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}  >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <TabPage
        tabArray={tabArray}
        module='CaseNextDate'
        saveDetails={addCaseNextDateDetails}
        exitModule={exitModule}
      />
    </>
  );
};

export default CaseNextDate;
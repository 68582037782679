import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Modal, Card } from 'react-bootstrap';
import EnlargableTextbox from 'components/common/EnlargableTextbox';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { useSelector, useDispatch } from 'react-redux';
import { formChangedAction } from 'actions';
import { stateListAPI } from 'actions/StateMaster/stateMasterAction.js';
import { toast } from 'react-toastify';
import { getLitigationTypeDetailListAPI, litigationTypeDetailAction } from 'actions/LitigationTypesDetail/litigationTypesDetailAction';

const LitigationTypeMasterDetailsData = () => {
	const dispatch = useDispatch();
	const [rowData, setRowData] = useState([]);
	const [perPage, setPerPage] = useState(15);
	const [modalShow, setModalShow] = useState(false);
	const [paramsData, setParamsData] = useState({});
	const [formHasError, setFormError] = useState(false);
	var responseLitigationTypCode = localStorage.getItem("EncryptedResponseLitigationTypeMasterCode")
	let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
	const headers = {
		Authorization: `Bearer ${JSON.parse(token).value}`
	};

	const columnsArray = [
		'S.No',
		'Litigation Detail Code',
		'Litigation Types Detail',
		'Status'
	];

	const emptyRow = {
		id: rowData.length + 1,
		// encryptedCountryCode: localStorage.getItem("EncryptedresponseLitigationTypCode"),
		subLitigationCode: '',
		litigationTypeDetailName: '',
		activeStatus: 'Active',
		addUser: localStorage.getItem("LoginUserName"),
	}

	const litigationTypesDetailReducer = useSelector((state) => state.rootReducer.litigationTypesDetailReducer)
	const litigationTypesDetailData = litigationTypesDetailReducer.litigationTypeDetail;

	const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
	var formChangedData = formChangedReducer.formChanged;

	const litigationTypeDetailErrorReducer = useSelector((state) => state.rootReducer.litigationTypeDetailErrorReducer)
	const litigationTypeDetailError = litigationTypeDetailErrorReducer.litigationTypeDetailError;

	useEffect(() => {
		if (litigationTypesDetailReducer.litigationTypeDetail.length > 0) {
			setRowData(litigationTypesDetailData);
		}
	}, [litigationTypesDetailData, litigationTypesDetailReducer])

	useEffect(() => {
		if (responseLitigationTypCode) {
			getLitigationTypeDetailList(responseLitigationTypCode)
		}
	}, [responseLitigationTypCode]);

	const getStates = async (selectCountryCode) => {
		const data = {
			countryCode: selectCountryCode ? selectCountryCode : "",
			isList: true
		}
		await dispatch(stateListAPI(
			data,
			(res) => {

				if (res && res.length > 0) {
					dispatch(litigationTypeDetailAction(res))

				} else {
					dispatch(litigationTypeDetailAction([]))
				}
			},
			(err) => {
				console.log("something went wrong")
				dispatch(litigationTypeDetailAction([]))
			}
		));
	};

	const getLitigationTypeDetailList = async (litigationCode, index) => {
		const data = {
			LitigationCode: litigationCode,
		};
		// setIsLoading(true);
		await dispatch(
			getLitigationTypeDetailListAPI(
				data,
				res => {
					// setIsLoading(false);
					let litigationDetailData = [];

					if (res.data.data.litigationTypeDetailList.length > 0) {
						dispatch(litigationTypeDetailAction(res.data.data.litigationTypeDetailList))
					} else {
						dispatch(litigationTypeDetailAction())
					}
				},
				err => {
					dispatch(litigationTypeDetailAction(err?.data.message))

					console.log(err?.data.message);
				},
				headers
			)
		);
	};

	const validateLitigationTypeDetailsForm = () => {
		let isValid = true;

		if (litigationTypesDetailData && litigationTypesDetailData.length > 0) {
			litigationTypesDetailData.forEach((row, index) => {
				if (!row.litigationTypeDetailName) {
					isValid = false;
					setFormError(true);
				}
			});
		}

		if (isValid) {
			setFormError(false);
		}

		return isValid;
	};

	const handleAddRow = () => {
		let formValid = validateLitigationTypeDetailsForm();
		if (formValid) {
			litigationTypesDetailData.unshift(emptyRow);
			dispatch(litigationTypeDetailAction(litigationTypesDetailData));
		}
	}

	const handleFieldChange = (e, index) => {
		const { name, value } = e.target;
		var litigationTypeDetailData = [...rowData];
		litigationTypeDetailData[index][name] = value;
		litigationTypeDetailData = Object.keys(rowData).map(key => {
			return rowData[key];
		});
		dispatch(litigationTypeDetailAction(litigationTypeDetailData));
		if (litigationTypeDetailData[index].encryptedStateCode) {
			dispatch(
				formChangedAction({
					...formChangedData,
					litigationTypeDetailUpdate: true
				})
			);
		} else {
			dispatch(
				formChangedAction({
					...formChangedData,
					litigationTypeDetailAdd: true
				})
			);
		}
	}

	const checkDuplicateLitigationTypeDetail = (litigationTypeDetailName, index) => {
		if (!litigationTypeDetail) return;
		const isDuplicate = rowData.some(
			(item, i) =>
				item.litigationTypeDetailName.toLowerCase() === litigationTypeDetailName.toLowerCase() && i !== index
		);

		if (isDuplicate) {
			toast.error(`The litigation type detail "${litigationTypeDetailName}" is already in use!`, {
				theme: 'colored',
				autoClose: 10000
			});
		}
	};

	return (
		<>
			<Card className="h-100 mb-2">
				<FalconCardHeader
					title="Litigation Details"
					titleTag="h6"
					className="py-2"
					light
					endEl={
						<Flex>
							<div >
								<Button
									variant="primary"
									size="sm"
									className="btn-reveal"
									type="button"
									onClick={handleAddRow}
								>
									Add Litigation Details
								</Button>
							</div>

						</Flex>
					}
				/>
				{
					litigationTypesDetailData && litigationTypesDetailData.length > 0 &&
					<Card.Body className="position-relative pb-0 p3px mr-table-card">
						<Form
							noValidate
							// validated={formHasError || (litigationTypeDetailError.litigationTypeDetailErr && litigationTypeDetailError.litigationTypeDetailErr.nameEmpty)}
							className="details-form"
							id="AddLitigationTypeDetailsForm"
						>
							<div className="table-responsive">
								<Table striped bordered responsive id="TableList" className="no-pb text-nowrap tab-page-table">
									<thead className='custom-bg-200'>
										<tr>
											{columnsArray.map((column, index) => (
												<th className="text-left" key={index}>
													{column}
												</th>
											))}
										</tr>
									</thead>
									<tbody id="tbody" className="details-form">
										{rowData.map((litigationTypesDetail, index) => (
											<tr key={index}>
												<td>
													{index + 1}
												</td>
												<td key={index}>
													<EnlargableTextbox
														name="subLitigationCode"
														placeholder="Litigation Detail Code"
														value={litigationTypesDetail.subLitigationCode}
														maxLength={5}
														disabled
														className="form-control"
														required
													/>
												</td>
												<td key={index}>
													<Form.Control
														name="litigationTypeDetailName"
														placeholder="Litigation Types Detail"
														onChange={(e) => handleFieldChange(e, index)}
														// onBlur={() => checkDuplicateLitigationTypeDetail(litigationTypesDetail.litigationTypeDetail, index)}
														value={litigationTypesDetail.litigationTypeDetailName}
														maxLength={50}
														className="form-control"
														required
													/>
												</td>

												<td key={index}>
													<Form.Select id="txtStatus" col="1" name="activeStatus"
														value={litigationTypesDetail.activeStatus}
														onChange={(e) => handleFieldChange(e, index)}
													>
														<option value="Active">Active</option>
														<option value="Suspended">Suspended</option>
													</Form.Select>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						</Form>
					</Card.Body>
				}
			</Card>
		</>
	)
}
export default LitigationTypeMasterDetailsData
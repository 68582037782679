import {
  GET_CASE_SCHEDULE_DATE_LIST_REQUEST,
  GET_CASE_SCHEDULE_DATE_LIST_SUCCESS,
  GET_CASE_SCHEDULE_DATE_LIST_FAILURE
} from "actions/Master/masterAction";

const initialState = {
  scheduleCaseDateList: [],
  scheduleCaseSelectDate: {},
};

export default function masterReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_CASE_SCHEDULE_DATE_LIST_REQUEST:
      return {
        ...state
      };
    case GET_CASE_SCHEDULE_DATE_LIST_SUCCESS:
      return {
        ...state,
        scheduleCaseDateList: action.payload
      };
    case GET_CASE_SCHEDULE_DATE_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case 'SCHEDULECASEDATELIST':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          scheduleCaseDateList: action.payload
        };
      }
    case 'SCHEDULECASEDATE':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          scheduleCaseSelectDate: action.payload
        };
      }
    default:
      return state;
  }
}
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { validateAdvocateAPI } from 'actions/AdvocateRegistration/advocateRegistrationAction';
import Lottie from 'lottie-react';
import celebration from './lottie/celebration';
import { Button, Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import { Link } from 'react-router-dom';
import { shortcutKeyCombinationAction } from 'actions';

const UpdateAdvocateSuccessBox = ({ reset }) => {
  const { id, emailOrMobileNo } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(shortcutKeyCombinationAction([]));
    validateAdvocate();
  }, []);

  const validateAdvocate = async () => {
    const requestData = {
      encryptedAdvocateCode: id,
      emailOrMobileNo: emailOrMobileNo
    };
    await dispatch(
      validateAdvocateAPI(
        requestData,
        res => {},
        err => {
          console.log(err?.data.message);
        }
      )
    );
  };

  return (
    <>
      <Row className="justify-content-center pt-9">
        <Logo width={200} />
        <Col className="text-center col-sm-10 col-lg-7 col-xxl-3">
          <div className="card theme-wizard mb-5">
            <div className="card-body py-5">
              <div className="tab-content">
                <div className="wizard-lottie-wrapper">
                  <div className="wizard-lottie mx-auto">
                    <Lottie animationData={celebration} loop={true} />
                  </div>
                </div>
                <h4 className="mb-1">Your account is all set!</h4>
                <p className="fs-9">Now you can access to your account</p>
                <Button
                  as={Link}
                  color="primary"
                  className="px-5 my-3"
                  to={`/login`}
                >
                  Re-Login
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UpdateAdvocateSuccessBox;